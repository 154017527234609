let backendHosts = {};
const hostname = window && window.location && window.location.hostname;




if (
    hostname.includes("bullforce-broker-frontend-staging.bullforce.co") 
   

) {
    backendHosts.CRM = "https://bullforce-trader-admin-backend-staging.bullforce.co/"
    backendHosts.WSURL = "wss://bullforce-trader-registration-service-staging.bullforce.co/"
    backendHosts.QR = "https://bullforce-trader-bullforce-broker-frontend-staging.bullforce.co/"
    backendHosts.REG = "https://bullforce-trader-registration-service-staging.bullforce.co/"
    backendHosts.PAYMENT = "https://bullforce-trader-payment-gateway-staging.bullforce.co/"
    //added on 13/08/24
    backendHosts.EXPURL="https://expert-advice-staging.bullforce.co/"
    backendHosts.DIPURL="https://diplomats-staging.bullforce.co/"
    backendHosts.TRURL="https://trade.bullforce.co"
    // added product links - 23/09/24 - dev-Srini

    backendHosts.WEALTH_VAULT_URL = "https://wealthvault-frontend-staging.bullforce.co/"
    backendHosts.SIGN_UP ="https://bullforce.co/signup"
}



else {
    backendHosts.CRM = "https://bullforce-trader-admin-backend.bullforce.co/"
    backendHosts.WSURL = "wss://bullforce-trader-registration-service.bullforce.co/"
    backendHosts.QR = "https://bullforce.co/"
    backendHosts.REG = "https://bullforce-trader-registration-service.bullforce.co/"
    backendHosts.PAYMENT = "https://bullforce-trader-payment-gateway.bullforce.co/"
    //added on 13/08/24
    backendHosts.EXPURL="https://expert-advice.bullforce.co/"
    backendHosts.DIPURL="https://diplomats.bullforce.co/"
    backendHosts.TRURL="https://trade.bullforce.co"
    // added product links - 23/09/24 - dev-Srini
    backendHosts.WEALTH_VAULT_URL = "https://wealthvault.bullforce.co/"
    backendHosts.SIGN_UP ="https://bullforce.co/signup"
}

export default backendHosts;