
import { useEffect } from 'react'
import { Grid, useMediaQuery } from '@mui/material'

// styles
import './products.css'

// Components
import MobileView from './MobileView'
import WebView from './WebView'

const Subscription = () => {

    const isSmallScreen = useMediaQuery('(max-width:960px)')

    return (
        <Grid>
            {isSmallScreen ? <MobileView /> : <WebView />}
        </Grid>
    )
}

export default Subscription