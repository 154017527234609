import { Grid, Box, Typography, Divider, Button } from '@mui/material'

// images
import logo from '../../ui-assets/adcampone.png'
import icon from '../../ui-assets/subscriptionOne.js.svg'
import icon1 from '../../ui-assets/subscriptionTwo.js.svg'

import backendHosts from '../../ui-utils/apiConfig';


const MobileView = () => {
    return (
        <Grid className='mbgimgev' sx={{ minHeight: '100vh' }}>
            <Grid container bgcolor='#0B1420'>
                <Grid item>
                    <Box className='mbxvew'>
                        <img src={logo} alt='logo' width={160} style={{ marginLeft: '20px' }} />
                    </Box>
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs={12} padding={3}>
                    <Typography align="center" className='morprodv'>
                        Our Products
                    </Typography>

                    <Typography align="center" className='mnweratv' mt='15px'>
                        A new era of trading experience with Bullforce
                    </Typography>
                </Grid>
            </Grid>

            <Grid container justifyContent='center' className='pacrdboxv'>
                <Grid item mb='5%'>
                    <Box className='mcrdboxv1'>
                        <Grid container justifyContent="space-between" alignItems="center">
                            <Grid item>
                                <Typography className="mbulftv" sx={{ ml: '25px', mt: '15px' }}>
                                    Demat <br /> Account
                                </Typography>
                            </Grid>

                            <Grid item>
                                <Typography className="mfr9tv" sx={{ mr: '25px', mt: '15px' }}>
                                    ₹ 499 <span className="myertv"> / Year </span>
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid container justifyContent="center" mt="10px">
                            <Grid item>
                                <Divider className='mdivdev' />
                            </Grid>
                        </Grid>

                        {/* First Plan */}
                        <Grid container justifyContent="center" alignItems="center" gap={2} padding={2}>
                            <Grid item>
                                <img src={icon} alt="Icon" width={30} />
                            </Grid>
                            <Grid item xs>
                                <Typography className="menergtv">
                                    25+ years, trusted by investors.
                                </Typography>
                            </Grid>
                        </Grid>

                        {/* Second Plan */}
                        <Grid container justifyContent="center" alignItems="center" gap={2} padding={2}>
                            <Grid item>
                                <img src={icon} alt="Icon" width={30} />
                            </Grid>
                            <Grid item xs>
                                <Typography className="menergtv">
                                    Unlock your stock market potential.
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid container justifyContent="center" mt="15px">
                        <a href={backendHosts.SIGN_UP} target='_blank' rel="noopener noreferrer">

                            <Button className="msubsbtnv">
                                <Typography className="msubsbtntxtv">Subscribe</Typography>
                            </Button>
                        </a>
                        </Grid>
                    </Box>
                </Grid>

                <Grid item mb='5%'>
                    <Box className='mcrdboxv2'>
                        <Grid container justifyContent="space-between" alignItems="center">
                            <Grid item>
                                <Typography className="mbulftv" sx={{ ml: '25px', mt: '15px' }}>
                                    Expert <br /> Advice
                                </Typography>
                            </Grid>

                            <Grid item>
                                <Typography className="mfr9tv" sx={{ mr: '25px', mt: '15px' }}>
                                    ₹  1,999 <span className="myertv"> / Year </span>
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid container justifyContent="center" mt="10px">
                            <Grid item>
                                <Divider className='mdivdev' />
                            </Grid>
                        </Grid>

                        {/* First Plan */}
                        <Grid container justifyContent="center" alignItems="center" gap={2} padding={2}>
                            <Grid item>
                                <img src={icon} alt="Icon" width={30} />
                            </Grid>
                            <Grid item xs>
                                <Typography className="menergtv">
                                    Tailored advice from SEBI registered advisors.
                                </Typography>
                            </Grid>
                        </Grid>

                        {/* Second Plan */}
                        <Grid container justifyContent="center" alignItems="center" gap={2} padding={2}>
                            <Grid item>
                                <img src={icon} alt="Icon" width={30} />
                            </Grid>
                            <Grid item xs>
                                <Typography className="menergtv">
                                    Stay ahead with expert insights.
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid container justifyContent="center" mt="15px">
                        <a href={backendHosts.EXPURL} target='_blank' rel="noopener noreferrer">

                            <Button className="msubsbtnv">
                                <Typography className="msubsbtntxtv">Subscribe</Typography>
                            </Button>
                        </a>
                        </Grid>
                    </Box>
                </Grid>

                <Grid item mb='7%'>
                    <Box className='mcrdboxv3'>
                        <Grid container justifyContent="space-between" alignItems="center">
                            <Grid item ml='25px' display="flex" flexDirection="column" alignItems="center">
                                <img src={icon1} alt="Icon"  width={34} style={{margin: '10px 50px 0 0'}}/>
                                <Typography className="mwelthvalv" mt='4px'>
                                    Wealth Vaults
                                </Typography>
                            </Grid>

                            <Grid item>
                                <Typography className="mfr9tv" sx={{ mr: '25px' }}>
                                    ₹  2,999 <span className="myertv"> / Year </span>
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid container justifyContent="center" mt="10px">
                            <Grid item>
                                <Divider className='mdivdev' />
                            </Grid>
                        </Grid>

                        {/* First Plan */}
                        <Grid container justifyContent="center" alignItems="center" gap={2} padding={2}>
                            <Grid item >
                                <img src={icon} alt="Icon" width={30} />
                            </Grid>
                            <Grid item xs>
                                <Typography className="menergtv">
                                    Energetic plan with High risk and High returns.
                                </Typography>
                            </Grid>
                        </Grid>

                        {/* Second Plan */}
                        <Grid container justifyContent="center" alignItems="center" gap={2} padding={2}>
                            <Grid item>
                                <img src={icon} alt="Icon" width={30} />
                            </Grid>
                            <Grid item xs>
                                <Typography className="menergtv">
                                    Traditional plan with Low risk and Moderate returns.
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid container justifyContent="center" mt="15px">
                        <a href={backendHosts.WEALTH_VAULT_URL} target='_blank' rel="noopener noreferrer">

                            <Button className="msubsbtnv">
                                <Typography className="msubsbtntxtv">Subscribe</Typography>
                            </Button>
                        </a>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default MobileView