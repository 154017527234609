// New page created -13/09/24 - Dev Srini
// New design created nd changes has made in complete file - 18/09/24 - Dev Srini
import { useEffect, useState, useCallback, } from 'react'
import { useHistory, useLocation } from "react-router-dom"
import { useMediaQuery, } from '@mui/material'

// optimze re renders - 16-09-24 - Dev Srini
import { debounce } from 'lodash'

import './campaign.css'

import { httpRequest } from '../../ui-utils/api'
import { publicIpv4 } from 'public-ip'

import MobileView from './MobileView'
import WebView from './WebView'

const Campaign = () => {
    const isSmallScreen = useMediaQuery('(max-width:950px)')

    const is1000px = useMediaQuery('(min-height:1000px)')

    const history = useHistory()

    const [userInfo, setUserInfo] = useState('')
    const [toggle, setToggle] = useState(false)
    const [anime, setAnime] = useState(true)
    const [error, setError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    // Regular expressions for email and mobile validation - 16-09-24 - Dev Srini
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    const phoneRegex = /^[0-9]{10}$/

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search)
    const referrer = queryParams.get('referral')

    // post api integrated for user count - 16/09/24 - Dev Srini
    useEffect(() => {
        const postVistorsData = async () => {
            try {
                let ipAddress = await publicIpv4()

                const result = await httpRequest({
                    endPoint: `api/users/count`,
                    method: "post",
                    instance: "instanceOne",
                    requestBody: {
                        ipAddress, pageName: "ad_campaign"
                    }
                })

                console.log(result, "PAGE VISITORS")

            }

            catch (err) {
                console.log("Error in visitors post API: ", err)
            }
        }

        if (window.location.pathname === '/ad_campaign') {
            postVistorsData()
        }

    }, [])


    useEffect(() => {
        window.Changemytab(5)
    }, [isSmallScreen])

    useEffect(() => {
        if (toggle) {
            setTimeout(() => {
                setAnime(!anime)
            }, 10000)
        }
    }, [toggle])

    // validation for mobile / email - 16-09-24 - Dev Srini
    const validateInput = useCallback(
        debounce((value) => {
            console.log('rendering')
            if (!emailRegex.test(value) && !phoneRegex.test(value)) {
                setErrorMessage('Please enter a valid email or phone number');
            } else {
                setErrorMessage('');
            }
        }, 500), // 500ms delay to debounce input
        []
    )

    const handleChangeUser = useCallback((e) => {
        setUserInfo(e.target.value)
        validateInput(e.target.value)
    }, [])

    const handleSubmit = async () => {
        if (userInfo.trim().length === 0) {
            setError(true)
            setErrorMessage('Email / Phone is require')
        }
        else if (errorMessage) {
            setError(true)
        }
        else {
            // referr added in payload - 16/09/24 - Dev Srini
            let ipAddress = await publicIpv4()
            let campaignLink = `${history.location.pathname} ${referrer ? referrer : ""}`
            try {
                const result = await httpRequest({
                    endPoint: `api/user/campaign`,
                    method: "post",
                    instance: "instanceOne",
                    requestBody: {
                        ipAddress, phoneEmail: userInfo, campaignLink
                    }
                })
                setToggle(true)
                setError(false)
                setErrorMessage('')
                console.log(result, "Social Campaign")
            }
            catch (err) {
                console.log("Error in Campaign, post API: ", err)
            }
        }
    }

    if (isSmallScreen) {
        return (
            <MobileView
                toggle={toggle}
                anime={anime}
                history={history}
                referrer={referrer}
                userInfo={userInfo}
                handleChangeUser={handleChangeUser}
                error={error}
                errorMessage={errorMessage}
                handleSubmit={handleSubmit}
            />
        )
    }
    else {
        return (
           <WebView 
                toggle={toggle}
                anime={anime}
                history={history}
                referrer={referrer}
                userInfo={userInfo}
                handleChangeUser={handleChangeUser}
                error={error}
                errorMessage={errorMessage}
                handleSubmit={handleSubmit}
                is1000px={is1000px}
           /> 
        )
    }
}

export default Campaign