import { TextField, Grid, Typography, Button, Card, 
    Snackbar, SnackbarContent, IconButton, FormControl,Box,
     FormHelperText,Hidden, InputAdornment,    Modal, ListItem,  List, Checkbox ,Divider} from '@mui/material'
//raviteja - 2023/10/16-start ->line 1

import FormControlLabel from '@mui/material/FormControlLabel'
import dayjs from 'dayjs'
import OtpInput from "react-otp-input";

import backendHosts from '../../ui-utils/apiConfig.js'

import React, { Component, } from 'react'
import { Close, GiteTwoTone } from '@mui/icons-material';
import { httpRequest } from '../../ui-utils/api'

import { withStyles, } from "@mui/styles";
import './main.css'
import { isMobile } from 'react-device-detect';

import { AnimatedOnScroll } from "react-animated-css-onscroll";

import validator from 'validator'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import APiConnectBG from "../../ui-assets/apiConnectBG.svg";

import landing2_mob from "../../ui-assets/screen.png";
import newMobilePic from "../../ui-assets/newMobileLandingPic.webp"
import newMobilePic1 from "../../ui-assets/newMobileLandingPic1.webp"

import wvOne from "../../ui-assets/wvOne.png"
import wvTwo from "../../ui-assets/wvTwo.png"
import wvThree from "../../ui-assets/wvThree.png"
import wvFour from "../../ui-assets/wvFour.png"
import wvFive from "../../ui-assets/wvFive.png"
import expertCardImg from "../../ui-assets/expertCard.webp"
import expertGirl from "../../ui-assets/ExpertGirl.webp"

import image from '../../ui-assets/moexport.webp'
import image2 from '../../ui-assets/moexport2.webp'


import styless from "../../ui-pages/landingpage/wealthvaults.module.css"
import expertCardStyles from "../../ui-pages/landingpage/expertCard.module.css"

import bitcoinLogo from "../../ui-assets/diplomatemain.png";
import bitcoinLogo1 from "../../ui-assets/mobile_diplomate.svg";
import globeback from "../../ui-assets/globeback.webp";

import connect_img from "../../ui-assets/connect1.webp"
import openAccPC from "../../ui-assets/open-account-pc.svg";
import academy_logo from "../../ui-assets/academy_logo.svg";
import academy_head from "../../ui-assets/academy_head.png";
import incubationPrgm from "../../ui-assets/incubation-progrm.svg";
import bullforceTick from "../../ui-assets/bullforce-list-tick.svg";
import bullforceTick1 from "../../ui-assets/bullforce-list-tick1.svg";
import andriodIcon from "../../ui-assets/androidIcon.svg";
import iosIcon from "../../ui-assets/iosIcon.svg";
import webIcon from "../../ui-assets/webApp.svg";
import arrowIcon from "../../ui-assets/arrowIcon.svg";
import largeArrowIcon from "../../ui-assets/largeArrowIcon.svg";
import highRewrds from "../../ui-assets/highRewrdIcon.svg";
import easyGrowth from "../../ui-assets/easyGrthIcon.svg";
import highProd from "../../ui-assets/highProd.svg";
import t1 from "../../ui-assets/t1.svg";
import t2 from "../../ui-assets/t2.svg";
import t3 from "../../ui-assets/t3.svg";
import nse from "../../ui-assets/nse.svg"
import bse from "../../ui-assets/bse.svg"
//import mcx from "../../ui-assets/mcx.svg"
import diplomats_img from "../../ui-assets/diplomats.svg"
import referall_img from "../../ui-assets/referal.svg"
import referall_copy from "../../ui-assets/copy.svg"
import Footer from "./fotter.js"

//Raviteja - 2023/10/16 - Start -line 23

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import {  DemoItem } from '@mui/x-date-pickers/internals/demo';
import zIndex from '@mui/material/styles/zIndex.js';
// Raviteja - 2023/10/16 - End line-27

// removed meta tag, no need here - 02/09/24 - Dev Srini

const styles = theme => ({
    select: {
        '&:before': {
            borderColor: 'red',
        },
        '&:after': {
            borderColor: 'red',
        }
    },
    icon: {
        fill: '#ffffff',
    },
    placeholder: {
        color: "red"
    },
    toolbar: {
        display: "flex",
        justifyContent: "space-between",
    },
    leftMenu: {
        display: "flex",
        alignItems: "center",
    },



    mobile_manin: {
        height: '100vh',
        display:'flex',
        justifyContent:'center',
        "@media screen and (max-width:960px)": {
            display: "none",

        }
    },

    mobile_manin1: {

        display: "none",

        "@media screen and (max-width:960px)": {
            height: 'auto',
            display: 'inline',



        }
    },



    opentradeBtn: {
        background: "#F74542",
        padding: "12px 15px !important",
        borderRadius: "20px !important",
        fontStyle: "normal !important",
        fontWeight: "300 !important",
        fontSize: "18px !important",
        lineHeight: "18px !important",
        color: "#FFFFFF !important",
        textTransform: "initial !important",
        fontFamily: "cardiuma-M !important"
    },
    joinDiplomtsBtn: {
        background: "#F74542",
        padding: "12px 45px !important",
        borderRadius: "20px !important",
        fontStyle: "normal !important",
        fontWeight: "500 !important",
        fontSize: "18px !important",
        lineHeight: "18px !important",
        color: "#FFFFFF !important",
        textTransform: "initial !important",
        fontFamily: "cardiuma-Bold !important"
    },
    joinCoustmer: {
        background: "#F74542 !important",
        padding: "12px 45px !important",
        borderRadius: "20px !important",
        fontStyle: "normal !important",
        fontWeight: "500 !important",
        fontSize: "18px !important",
        lineHeight: "18px !important",
        color: "#ffffff !important",
        textTransform: "initial !important",
        fontFamily: "cardiuma-Bold !important"
    },


    benifits: {
        fontStyle: "normal !important",
        fontWeight: "600 !important",
        fontSize: "38px !important",
        lineHeight: "56px !important",
        color: "#BCD171 !important",
        textAlign: "right !important",

        fontFamily: "cardiuma-M !important",
        // h1 tags margin start&end values removed - 02/09/24 - Dev Srini
        marginBlockStart: 0,
        marginBlockEnd: 0
    },
    benifitCards: {
        padding: "28px 15px",
        background: "#FFFFFF  !important",
        boxShadow: "0px 8px 8px rgba(32, 108, 255, 0.25)  !important",
        borderRadius: "24px  !important",
        // adjusted padding for diplomats cards - 01/10/24 - dev Srini
        "@media screen and (max-width:1843px)": {
            padding: "28px 15px"
        },

        "@media screen and (max-width:1782px)": {
            padding: "28px 40px"
        },

        "@media screen and (max-width:1565px)": {
            padding: "28px 30px"
        },

        "@media screen and (max-width:1348px)": {
            padding: "28px 20px"
        },

        "@media screen and (max-width:1285px)": {
            padding: "28px 10px"
        },

        "@media screen and (max-width:1147px)": {
            padding: "28px 25px"
        },

        "@media screen and (max-width:1084px)": {
            padding: "28px 15px"
        },

        "@media screen and (max-width:1013px)": {
            padding: "28px 25px"
        },

    },
    benifitCards_app: {
        padding: "28px 40px",
        background: "#0B1420  !important",
        justifyContent: 'center',
        borderRadius: "24px  !important",
        "@media screen and (max-width:960px)": {
          
            display: 'flex',

marginTop:'10px',

        }
    },
downloadcardsetting:{
    display: "flex", justifyContent: "space-around"
},
downloadcardsetting1:{
display: "flex", cursor: 'pointer',
"@media screen and (max-width:960px)": {
          
    justifyContent:'center',alignItems:'center' 

}


},
    benifitCardsTitle: {
        fontStyle: "normal !important",
        fontWeight: "600 !important",
        fontSize: "24px !important",
        lineHeight: "44px !important",
        color: " #093265 !important",
        fontFamily: "cardiuma-Bold !important",
        marginBlockStart: 0,
        marginBlockEnd: 0,
        // Adjust font for Diplomats cards - 01/10/24 -Dev Srini
        "@media screen and (max-width:1300px)": {
            fontSize: "18px !important"
        }
    },


    benifitCardsTitle_app: {
        fontStyle: "normal !important",
        fontWeight: "600 !important",
        fontSize: "24px !important",
        lineHeight: "44px !important",
        color: " #F74542 !important",
        fontFamily: "cardiuma-Bold !important",
        textAlign:'center',
        marginBlockStart: 0,
        marginBlockEnd: 0, 

        "@media screen and (max-width:960px)": {
            textAlign:'left',
           
        fontWeight: "400 !important",
        fontSize: "20px !important",
        
        
        fontFamily: "cardiuma-regular !important",



        }
    },
    benifitCardsCont: {
        fontStyle: "normal !important",
        fontWeight: "200 !important",
        fontSize: "16px !important",
        lineHeight: "24px !important",
        textAlign: "center !important",
        color: "#000000 !important",
        fontFamily: "cardiuma-regular !important",

        "@media screen and (max-width: 1400px)" : {
            fontSize: "14px !important",
        },

        "@media screen and (min-width: 1600px)" : {
            fontSize: "18px !important",
        }
    },


    franchiseTitle: {
        fontStyle: "normal ! important",

        fontSize: "32px ! important",
        lineHeight: "44px ! important",
        textAlign: "center ! important",
        color: "#FFFFFF ! important",
        padding: "30px 36px !important",
        fontFamily: "cardiuma-regular !important",
    },


    apiConectBG: {
        backgroundSize: "cover ! important",

        backgroundRepeat: "no-repeat ! important",
        backgroundPosition: "center center !important",
        height: "560px ! important",
        backgroundImage: `url(${APiConnectBG})`,
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "baseline",
        "@media screen and (max-width:960px)": {
            display: "none",
            backgroundImage: `none`,
            height: "0px ! important",
        }
    },
    gl: {
        backgroundSize: "cover ! important",

        backgroundRepeat: "no-repeat ! important",
        backgroundPosition: "center center !important",
        height: "1375px",
        backgroundImage: `url(${globeback})`,
        width: "1442px",

        opacity: '10%',



    },

    mobileback: {
        backgroundSize: "cover ! important",

        backgroundRepeat: "no-repeat ! important",
        backgroundPosition: "center center !important",
       
        backgroundImage: `url(${landing2_mob})`,
       

       



    },
    back_img: {


        backgroundRepeat: "no-repeat !important",
        backgroundSize: "cover ! important",


        backgroundPosition: "center center !important",


        width: "100vw",
        //background: '#252956',
        backgroundImage: `url(${globeback})`,
        //backgroundImage: `url(${globeback}),linear-gradient(45deg, rgba(37, 41, 86), rgba(37, 41, 86))`,
        //backgroundBlendMode: 'multiply',
    },
    downloadTradeTitle: {
        fontStyle: "normal !important",
        fontWeight: "400 !important",
        fontSize: "62px !important",
        lineHeight: "86px !important",
        color: "#F3B95B !important",
        textAlign: 'center',
        fontFamily: "cardiuma-M !important",
        marginBlockStart: 0,
        marginBlockEnd: 0,

        "@media screen and (max-width:960px)": {
            fontSize: "24px !important",
            textAlign: 'left',
            paddingLeft:'16px',
            lineHeight: "40px !important"
        }
    },
    accessBFList: {
        fontStyle: "normal !important",
        fontWeight: "400 !important",
        fontSize: "62px !important",
        lineHeight: "72px !important",
        textAlign: "center !important",
        color: "#BCD171 !important",
        paddingBottom: "20px !important",
        fontFamily: "cardiuma-M !important",
        marginBlockStart: 0,
        marginBlockEnd: 0, 
    },
    accessBFList2: {


        marginTop: '10px !important',

        fontStyle: "normal !important",
        color: "#ffffff !important",
        fontSize: "24px !important",
        lineHeight: "44px !important",

        marginLeft: "15px !important",
        fontFamily: "cardiuma-regular !important",
    },
    bullforceAPIHead: {
        fontStyle: "normal !important",
        fontWeight: "600 !important",
        fontSize: "62px !important",
        lineHeight: "86px !important",
        color: "#F3B95B !important",
        textAlign: "center",
        width: "100%",
        fontFamily: "cardiuma-Bold !important",
        marginBlockStart: 0,
        marginBlockEnd: 0,
    },
    BFIncubationProg: {
        fontStyle: "normal ! important",
        fontWeight: "400 ! important",
        fontSize: "62px ! important",
        lineHeight: "72px ! important",

        color: "#F3B95B ! important",

        paddingBottom: "20px",
        fontFamily: "cardiuma-M !important",
    },

    bullforceTick_img: {
        height: '35px',
        width: '35px'
    },

    openaccountcard: {
        display: 'flex',
        // width: '100%',
        padding: '30px 0 50px 0',

        marginBlockStart: 0,
        marginBlockEnd: 0,

        "@media screen and (max-width:960px)": {

            width: '100%',
            display: 'inline',
            padding: '16px 16px 16px 16px',

        }
    },

    openaccountcard_1: {
        // // adjusted a/c open form position - 02/09/24 - Dev Srini
        width: '60%',
        padding: '0 0 0 3.5%',

        "@media screen and (max-width:960px)": {


            width: '100%',
            padding: '16px 16px 0px 16px',
        }
    },

    openaccount: {
        color: '#FFFFFF',
        fontFamily: 'cardiuma-M !important',
        fontSize: '40px !important',
        fontStyle: 'normal',
        fontWeight: '500',
        lineHeight: '56px',
        marginBlockEnd: 0,

        "@media screen and (max-width:960px)": {
            fontSize: '24px !important',
        }

    },
    openaccountsub1: {
                color: '#F3B95B',
        fontFamily: 'cardiuma-regular !important',
        fontSize: '16px !important',
        fontStyle: 'normal',
        fontWeight: '400',
cursor:'pointer',
textAlign:'center',
        "@media screen and (max-width:960px)": {

            
            fontSize: '14px !important',

        }
    },
    openaccountsub2: {
        color: '#42A0F7',
        fontFamily: 'cardiuma-regular !important',
        fontSize: '16px !important',
        fontStyle: 'normal',
        fontWeight: '400',
cursor:'pointer',
textAlign:'center',
        "@media screen and (max-width:960px)": {

            textAlign:'left',          
            fontSize: '14px !important',

        }
    },
    openaccountsub: {
        color: '#F3B95B',
        fontFamily: 'cardiuma-regular !important',
        fontSize: '24px !important',
        fontStyle: 'normal',
        fontWeight: '400',

        padding: '32px 0px',
        "@media screen and (max-width:960px)": {

            padding: '22px 0px',
            fontSize: '18px !important',

        }
    },
    errtxt: {
        color: '#F74542 !important', fontFamily: 'cardiuma-regular !important', fontSize: '12px !important',
    },
    openaccounting: {
        margintop: '2%',

width: '75%',
        "@media screen and (max-width:960px)": {

            width: '100%',
            marginTop: '5%',


        }
    },

    formCard_grid: {
        // adjusted a/c open form position - 02/09/24 - Dev Srini
        width: '30%',
        marginTop: '6%',


        "@media screen and (max-width:960px)": {

            width: '100%',

            marginLeft: '0%',
            padding: '0px 16px 16px 16px',

        }
    },

    formCard: {
        borderRadius: '12px',
        marginTop: '20%',

        boxShadow: '0px 8px 8px 0px rgba(32, 108, 255, 0.25)',
        padding: '40px 52px',
        background: '#FFFFFF !important',

        "@media screen and (max-width:960px)": {
            marginLeft: '0%',
            marginTop: '5%',
            padding:'0px 10px 25px 0px',
            background: '#0B1420 !important',
            width: '100%',

        }

    },
    /*dev-Ayush 13-7-2024*/
    openTradingAccForm : {
        "@media screen and (max-width:960px)": {
            backgroundColor : "#ffffff !important",
            borderRadius : "8px !important",
            width : "100% !important",
            "& .MuiInputBase-root": { height: "52px !important"},
            "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
                borderRadius: "10px !important",
              },
        }
    }
    /*dev-Ayush 13-7-2024*/
    ,
    /*muilt tradeing*/
    muilthead_card: {
        padding: '80px 72px 31px 72px',

        "@media screen and (max-width:960px)": {
            padding: '32px 35px 32px 35px',
        }
    },

    muilthead_card1: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',

        "@media screen and (max-width:960px)": {
            display: 'inline',
            padding : "0px 19px"
        }
    },

    muilimgsize: {
        width: '90%',
        height: '90%',
    },

    muilimg: {
        width: '80%',
        paddingLeft: '30px',

        "@media screen and (max-width:960px)": {
            width: '100%',
            paddingLeft: '0px',
            display: 'flex',
            justifyContent: 'center',

        }
    },
    muilimg1: {
        width: '20%',
        paddingLeft: '70px',

        "@media screen and (max-width:960px)": {
            width: '100%',
            paddingLeft: '0px',
            display: 'flex',
            justifyContent: 'center',   
            margin: '0 0 3% 0'

        }
    },

    muiltsubcard: {
        width: '40%',
        marginLeft: '5%',
        marginTop: '5%',

        "@media screen and (max-width:960px)": {
            width: '100%',

            marginLeft: '0%',
            marginTop: '0%',
            padding: '16px 16px 42px 16px',
        }
    },

    muilthead: {
        color: '#3E3E3E',
        fontFamily: 'cardiuma-Bold !important',
        fontSize: '40px !important',
        fontStyle: 'normal',
        fontWeight: '600',
        // lineHeight: '56px',
        marginBlockStart: 0,
        marginBlockEnd: 0,

        "@media screen and (max-width:960px)": {
            fontSize: '24px !important',
        }

        /* 140% */
    },

    muiltsub: {
        color: '#3E3E3E',
        fontFamily: ' cardiuma-regular !important',
        fontSize: '24px !important',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '32px',

        "@media screen and (max-width:960px)": {
            fontSize: '18px !important',
            padding:'16px'
        }

        /* 133.333% */
    },

    /*diplomate*/
    dipmaincard: {
        width: '50%',

        "@media screen and (max-width:960px)": {
            width: '100%',
        }
    },

    dipmaincard_sub: {
        padding: '80px 0px 0px 72px',
        width: '100%',

        "@media screen and (max-width:960px)": {
            padding: '16px 42px 16px 42px',
        }
    },

    diplomatshead: {
        color: '#F3B95B',
        fontFamily: 'cardiuma-Bold !important',
        fontSize: '40px !important',
        fontStyle: 'normal',
        fontWeight: '600',
        lineHeight: '56px',
        marginBlockStart: 0,
        marginBlockEnd: 0,

        /* 140% */
        "@media screen and (max-width:960px)": {
            fontSize: '24px !important',
        }
    },

    diplomatshead_sub: {
        color: '#FFFFFF',
        fontFamily: 'cardiuma-regular !important',
        fontSize: '24px !important',
        fontStyle: 'normal',
        fontWeight: '400',
        paddingTop: '32px',
        lineHeight: '32px',
        "@media screen and (max-width:960px)": {
            fontSize: '18px !important',
        }
    },

    diplomat_im: {
        marginTop: '-50%',


        "@media screen and (max-width:960px)": {
            display: 'none'
        }

    },

    diplomat_im1: {
        display: 'none',

        "@media screen and (max-width:960px)": {

            width: '100%',
            display: 'flex',
            justifyContent: 'center',


        }

    },

    diplomat_p2_card: {
        display: 'flex',
        padding: '0px 0px 0px 70px',
        width: '100%',
        marginTop: '-5%',
        "@media screen and (max-width:960px)": {


            display: 'none',


        }
    },

    diplomat_p2_card2: {
        justifyContent: 'space-around',
        padding: '32px 70px 70px 70px',
        width: '100%',
        display: 'flex',

        "@media screen and (max-width:960px)": {


            display: 'none',


        }
    },

    diplomat_but_card2: {
        display: 'flex',
        width: '15%',
        padding: '0px 0px 128px 0px',
        justifyContent: 'center',

        "@media screen and (max-width:960px)": {

            width: '90%',
            padding: '0px 40px 42px 40px',



        }

    },

    diplomat_but: {
        background: '#F74542 !important',
        padding: '12px 45px !important',
        borderRadius: '20px !important',
        fontStyle: 'normal !important',
        fontWeight: '500 !important',
        fontSize: '18px !important',
        lineHeight: '18px !important',
        color: '#FFFFFF !important',
        texttransform: 'initial !important',
        fontFamily: 'cardiuma-Bold !important',

        "@media screen and (max-width:960px)": {}
    },

    /*Connect*/
    connect_card: {
        backgroundColor: '#FFFFFF',
        padding: '80px 72px 0px 72px',

        "@media screen and (max-width:960px)": {
            padding: '24px 16px 0px 24px',

        }
    },

    connect_head: {
        color: '#F3B95B',
        fontFamily: 'cardiuma-M !important',
        fontSize: '40px !important',
        fontStyle: ' normal !important',
        fontWeight: '400',
        lineHeight: '36px',
        marginBlockStart: 0,
        marginBlockEnd: 0,

        "@media screen and (max-width:960px)": {
            fontSize: '24px !important',
            marginLeft : "16px !important",
            marginRight : "16px !important"
        }

        /* 140% */
    },

    conectimgmaincard: {
        width: '100%',
        display: 'flex',

        "@media screen and (max-width:960px)": {

            display: 'inline',



        }


    },

    conectimgcars: {
        width: '40%',
        padding: '31px 0px 0px 0px',

        "@media screen and (max-width:960px)": {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            padding: '31px 16px 16px 16px',

        }


    },

    connect_sub_card: {
        padding: '10px 10px 10px 10px',
        width: '50%',
        margin: '6% 0 0 10%',

        "@media screen and (max-width:960px)": {
            width: '100%',
            padding: '16px 16px 32px 10px',
            margin: '3% 0 0 2%',
        }
    },
    connect_sub_card1: {
        padding: '31px 0px 0px 50px',
        width: '100%',
        marginTop : "40px",
        "@media screen and (max-width:960px)": {
            width: '100%',
            padding: '16px 16px 32px 16px',

        }
    },

    connect_sub: {
        color: '#3E3E3E',
        fontFamily: 'cardiuma-regular !important',
        fontSize: '24px !important',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '32px',

        "@media screen and (max-width:960px)": {
            fontSize: '14px !important',

        }
    },

    connect_but_card: {
        width: '35%',

        "@media screen and (max-width:960px)": {
            width: '100%',



        }
    },

    /*Academy*/

    academymaincard: {
        width: '100%',
        backgroundColor: '#FFFFFF',
        marginTop: '0px',

        "@media screen and (max-width:960px)": {
            width: '100%',
            backgroundColor: '#FFFFFF',
            marginTop: '0px',
        }
    },

    academyimgcard: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        padding: '0px 50px 0px 160px',

        backgroundColor: '#FFFFFF',

        "@media screen and (max-width:960px)": {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',

            padding: '0px 16px 0px 16px',
            marginTop: '0px',
        }
    },

    academyimg: {
        width: '100%',
        height: '100%',


        "@media screen and (max-width:960px)": {
            width: '100%',
            height: '100%',


        }
    },

    academyimg1: {
        width: '15%',
        height: '15%',

        "@media screen and (max-width:960px)": {
            width: '25%',
            height: '25%',


        }
    },

    academyimgcard1: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginTop: '42px',


        "@media screen and (max-width:960px)": {
            marginTop: '20px',

        }

    },

    academymainheadcard: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginTop: '50px',

        "@media screen and (max-width:960px)": {
            marginTop: '0px',
            padding: '16px 24px 0px 24px',
        }
    },

    academymainhead: {
        color: '#BCD171',
        textAlign: 'center',
        fontFamily: ' cardiuma-Bold !important',
        fontSize: '40px !important',
        fontStyle: 'normal',
        fontWeight: '600',
        lineHeight: '56px',
        marginBlockStart:0,
        marginBlockEnd:0,


        "@media screen and (max-width:960px)": {
            fontSize: '24px !important',
            lineHeight: 'normal',
        }

        /* 140% */
    },

    academymainsubcard: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        padding: '42px 136px 0px 42px',

        "@media screen and (max-width:960px)": {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            padding: '16px 24px 16px 24px',
        }
    },

    academymainsub: {
        textAlign: 'center',
        fontFamily: 'cardiuma-regular !important',
        fontSize: '24px !important',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '32px',
        color: '#FFFFFF',

        "@media screen and (max-width:960px)": {
            fontSize: '14px !important',
            lineHeight: 'normal',
        }
    },

    academysubimagecard: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        marginTop: '20px',

        "@media screen and (max-width:960px)": {
            width: '100%',
            display: 'inline',
        }
    },

    academysubimagecard1: {
        width: '30%',
marginTop:'50px',
        "@media screen and (max-width:960px)": {
            width: '100%',

        }

    },

    academysubtit: {
        color: '#DCDFFF',

        fontFamily: 'cardiuma-M !important',
        fontSize: '24px !important',
        fontStyle: 'normal',
        fontWeight: '500',
        lineHeight: '72px',
        marginBlockStart: 0,
        marginBlockEnd: 0,

        "@media screen and (max-width:960px)": {
            fontSize: '16px !important',

        }

        /* 300% */
    },
    academysubtit1: {
        color: '#E1F1A9',

        fontFamily: 'cardiuma-M !important',
        fontSize: '24px !important',
        fontStyle: 'normal',
        fontWeight: '500',
        lineHeight: '72px',
        marginBlockStart: 0,
        marginBlockEnd: 0,

        "@media screen and (max-width:960px)": {
            fontSize: '16px !important',

        }

        /* 300% */
    },
    academysubtit2: {
        color: '#FCBAC0',

        fontFamily: 'cardiuma-M !important',
        fontSize: '24px !important',
        fontStyle: 'normal',
        fontWeight: '500',
        lineHeight: '72px',
        marginBlockStart: 0,
        marginBlockEnd: 0,

        "@media screen and (max-width:960px)": {
            fontSize: '16px !important',

        }

        /* 300% */
    },
    academydisp: {
        color: '#FFFFFF',

        fontFamily: 'cardiuma-regular !important',
        fontSize: '14px !important',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '22px',

        "@media screen and (max-width:960px)": {
            fontSize: '16px !important',
            width : "306px"
        },
        "@media screen and (max-width:350px)": {
            fontSize: '16px !important',
            width : "100%"
        }

        /* 300% */
    },
    academybutcard: {
        width: '20%',
        marginTop: '32px',
        paddingBottom: '136px',

        "@media screen and (max-width:960px)": {
            width : "100%",
            paddingBottom: '75px',
            display : "flex" ,
            justifyContent : "center"
        } 
    },

    /*Incubation*/

    incubationmaincard: {
        padding: '130px 0px 0px 72px',
        backgroundColor: '#FFFFFF',

        "@media screen and (max-width:960px)": {
            padding: '42px 16px 20px 16px',

        }

    },

    incubationhead: {
        color: '#F3B95B',
        fontFamily: 'cardiuma-Bold !important',
        fontSize: '40px !important',
        fontStyle: 'normal',
        fontWeight: '600',
        lineHeight: '72px',
        marginBlockStart: 0,
        marginBlockEnd: 0,

        /* 180% */
        "@media screen and (max-width:960px)": {
            fontSize: '24px !important',
            lineHeight: ' normal',
            marginLeft : "16px !important"
        }
    },

    incubationsubcard: {
        width: '50%',
        padding: '32px 0px 32px 0px',

        "@media screen and (max-width:960px)": {
            width: '100%',
            padding: '0px 16px 16px 16px',

        }
    },

    incubationsub: {
        color: '#0B1420',
        fontFamily: 'cardiuma-regular !important',
        fontSize: '24px !important',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '32px',

        "@media screen and (max-width:960px)": {
            fontSize: '14px !important',
            lineHeight: ' normal',

        }
    },

    incubationsubimg: {
        width: '50%',
        height : "420px",
        display: 'flex',
        justifyContent: 'center',

        "@media screen and (max-width:960px)": {
            display: 'none',
        }
    },

    incubationsubimg1: {

        display: 'none',

        "@media screen and (max-width:960px)": {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            padding: '16px 16px 16px 16px',
        }
    },

    incubationopt: {
        color: '#0B1420',
        fontFamily: 'cardiuma-regular !important',
        fontSize: '24px !important',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: 'normal',
        marginLeft: '15px !important',

        "@media screen and (max-width:960px)": {
            fontSize: '14px !important',
            marginLeft: '10px !important',
        }
    },

    incubationbutcard: {
        width: '50%',
        padding: '0px 0px 128px 0px',

        "@media screen and (max-width:960px)": {
            width: '95%',
            padding: '0px 0px 32px 0px',
        }

    },
    apicont_card: {
        backgroundColor: "#111", padding: "100px 90px 50px 90px",

        "@media screen and (max-width:960px)": {
            display: 'none'

        }
    },
    apicont_card1: {
        backgroundColor: "#111",
        padding: "45px 120px 45px 120px",
        "@media screen and (max-width:960px)": {
            display: 'none'

        }
    },
    downloadcard: {
        backgroundColor: " #1D2346", padding: "50px 30px",
        "@media screen and (max-width:960px)": {
            padding: "20px 16px",

        }
    },


    input: {
        "&:-webkit-autofill": {
            WebkitBoxShadow: "0 0 0 1000px #ffffff inset",
            WebkitTextFillColor: '#1B2636'

        },
        color: "red", fontFamily: 'cardiuma-regular !important', fontSize: '18px', height: '30px'
    },
    input2: {
        "&:-webkit-autofill": {
            WebkitBoxShadow: "0 0 0 1000px #f74542 inset",
            WebkitTextFillColor: '#FFFFFF'

        },
        color: "red", fontFamily: 'cardiuma-regular !important', fontSize: '18px', height: '30px'
    },
    input1: {
        "&:-webkit-autofill": {
            WebkitBoxShadow: window.innerWidth <960 ? "0 0 0 1000px #0B1420 inset" :"0 0 0 1000px #ffffff inset",
            WebkitTextFillColor: window.innerWidth <960 ? '#ffffff':'#0B1420'

        },
        color: "red", fontFamily: 'cardiuma-regular !important', fontSize: '18px', height: '30px'
    },
    moretxt: {
        color: '#F74542',
        fontFamily: 'cardiuma-regular !important',
        fontSize: '16px !important',
        fontStyle: 'normal',
        fontWeight: '500',
        lineHeight: 'normal',
        marginLeft: '15px !important',
        cursor:'pointer',
        "@media screen and (max-width:960px)": {
            display: 'none'

        }
    },
    applay_txt: {
        color: '#3E3E3E',
        fontFamily: 'cardiuma-Bold !important',
        fontSize: '20px !important',
        fontStyle: 'normal',
        fontWeight: '700',
        lineHeight: 'normal',


    },
    connect_card_but: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-evenly'
    },
    connect_card_but1: {
        width: '45%',

    },
    connect_but: {

        background: "#ffffff !important",
        padding: "8px !important",
        borderRadius: "38px !important",
        fontStyle: "normal !important",
        fontWeight: "700 !important",
        fontSize: "18px !important",

        color: "#F74542 !important",
        textTransform: "initial !important",
        fontFamily: "cardiuma-Bold !important",
        border: '1px solid #D4D4D4 !important',

        "&:hover": {
            background: "#F74542 !important",
            padding: "8px !important",
            borderRadius: "38px !important",
            fontStyle: "normal !important",
            fontWeight: "700 !important",
            fontSize: "18px !important",

            color: "#FFFFFF !important",
            textTransform: "initial !important",
            fontFamily: "cardiuma-Bold !important",
            border: '1px solid #D4D4D4 !important',

        },

    },
    connect_but1: {


        background: "#F74542 !important",
        padding: "8px !important",
        borderRadius: "38px !important",
        fontStyle: "normal !important",
        fontWeight: "700 !important",
        fontSize: "18px !important",

        color: "#FFFFFF !important",
        textTransform: "initial !important",
        fontFamily: "cardiuma-Bold !important",
        border: '1px solid #D4D4D4 !important',


    },

    bseimg:{
        marginTop: '18px',
        "@media screen and (max-width:960px)": {
            marginLeft: '18px',
            marginTop:'0px'

        }
    },
    learncard:{
         width: '100%', paddingTop: '13px' ,
         "@media screen and (max-width:960px)": {
            display : "flex",
            justifyContent : "start",
            flexDirection : "column !important",
            alignItems : "center",
            padding:'16px'
        },
        "@media screen and (max-width:350px)": {
            paddingLeft:'20px'
        }
    },
    /*dev-Ayush 13-07-2024 */
    learnImage:{
       "@media screen and (max-width:960px)": {
        display : "flex",
        justifyContent : "center",
        paddingLeft:'0px',
        // paddingBottom : "20px"
    },
    },
    
    academyDispGrid : { 
        "@media screen and (max-width:960px)": {
        marginTop : "10px !important"
    }
},
 /*dev-Ayush 13-07-2024 */
    downloadimg:{
    display: 'flex', justifyContent: 'flex-end', marginTop: '-3%', marginLeft: '40%',
    "@media screen and (max-width:960px)": {
            
        display: 'none',

    }
    },
    downloadimg1:{
        "@media screen and (max-width:960px)": {
            
            display: 'none',
    
        }
    },
maindownloadcard:{
    width:'100%',display:'flex',justifyContent:'space-evenly',height:'auto',
    "@media screen and (max-width:960px)": {
        display: 'inline',
    },

    refcard:{
      
        
    },




},

maindownloadcard1:{

    width:'31%',
    background:'#0B1420',
    borderRadius:'24px',
   padding:'2% 0%',
   "@media screen and (max-width:960px)": {
    width:'100%',
    marginTop:'10px',
    display:'flex'
}
},
maindownloadcard2:{
display:'flex',justifyContent:'center', width:'100%',
"@media screen and (max-width:960px)": {
    alignItems:'center',paddingLeft:'12%',justifyContent:'flex-start',
}
},
maindownloadcard22:{
    display:'flex',justifyContent:'center',
    "@media screen and (max-width:960px)": {
        alignItems:'center',justifyContent:'flex-start',
    }
    },
    
maindownloadcard3:{
    display:'flex', width:'100%',
    "@media screen and (max-width:960px)": {
        display:'flex',alignItems:'center',background:'blue', width:'70%',
    }
    },
    
    maindownloadsubcard:{
        width:'100%',
        display:'flex',justifyContent:'center',
        "@media screen and (max-width:960px)": {
            width:'30%',
    }
    },
downloadcardimg:{

   

    "@media screen and (max-width:960px)": {
        width:'75%',
    height:'75%',
    background:'red'
    }
},

downloadcardimg1:{

   

    "@media screen and (max-width:960px)": {
        width:'60%',
    height:'60%'
    }
},
muilr_card_new:{

    backgroundColor: "#fff",
    paddingBottom: '100px',
    "@media screen and (max-width:960px)": {
        
        paddingBottom: '25px'
    }
},
selecttitle: {
    color: '#3E3E3E',
    fontFamily: 'cardiuma-Bold ! important',
    fontSize: '20px ! important ',
    fontStyle: 'normal',
    fontWeight: '700 ! important',
    lineHeight: '18px ! important',
},
modalcard: {

    padding: '15px',
    
    borderRadius: '8px',

},
selectcard1: {
    width: '100%',
    height: '150px',
    flexshrink: '0',
    borderradius: '8px',
    border: '1px solid #EBEBEB',
    background: '#FFF',
    marginBottom: '8px',
    marginTop: '12px',
    marginLeft: '20px',


},
selectcard2: {
    width: '100%',
    height: '200px',
    flexshrink: '0',
    borderradius: '8px',
    border: '1px solid #EBEBEB',
    background: '#FFF',
    marginBottom: '8px',
    marginLeft: '20px'


},
selectcard3: {
    width: '100%',
    
    flexshrink: '0',
    borderradius: '8px',
    border: '1px solid #EBEBEB',
    background: '#FFF',
    marginLeft: '20px'
},
selectbar: {

    textAlign: 'center',
    width: '100%',
    height: '35px',
    flexshrink: '0',
    background: '#F3F3F3',
    marginRight: '13px'

},
selectheading: {
    color: '#3E3E3E',
    fontFamily: 'cardiuma-SemiBold ! important',
    fontSize: '16px ! important',
    fontStyle: 'normal',
    fontWeight: '600 ! important',
    lineHeight: '18px ! important',
    textAlign: 'center',
    padding: '7px'


},
listone: {
    width: '279px',
    height: '113px',
    flexshrink: '0',
    padding: '0px 5px'


},
listtwo: {
    width: '288px',
    height: '117px',
    flexshrink: '0',
    padding: '5px'

},
listthree: {
    width: '308px',
    height: '137px',
    flexshrink: '0',
    padding: '5px'


},
listfour: {
    width: '405px',
    height: '137px',
    flexshrink: '0',
    padding: '4px'


},
listfive: {
    width: '344px',
    
    flexshrink: '0',
    padding: '4px'
},
listsix: {
    width: '361px',
    height: '135px',
    flexshrink: '0',
    padding: '5px'
},
listicon: {

    "&::before": {
        content: '"\\2022"', // Unicode character for the bullet (•)
        display: 'inline-block',
        width: '1em', // Adjust the width of the bullet
        marginRight: '1px', // Adjust the space between the bullet and the text
    }
},
basicpointicon: {
    "&::before": {
        content: '"\u2023"', // Unicode character for the bullet (•)
        display: 'inline-block',
        width: '1em', // Adjust the width of the bullet
        marginRight: '1px', // Adjust the space between the bullet and the text
        color: '#FFFFFF'
    }
},

listpoints: {
    listStyleType: 'circle',
    color: '#000 ! important',
    fontFamily: 'cardiuma-regular ! important',
    fontSize: '12px ! important',
    fontStyle: 'normal ! important',
    fontWeight: '400 ! important',

},
labelinput1: {
    width: '250px',
    height: '22.561px',
    flexShrink: 0,
    color: '#0B1420',
    fontFamily: 'cardiuma-regular !important',
    fontSize: '16px',
    lineHeight: 'normal',
    fontStyle: 'normal',
    fontWeight: '400',

},
underline: {

    width: '395px',
    height: '1px',
    flexshrink: 0,
    borderRadius: '8px',
},
buttonheading: {
    color: '#3E3E3E !important ',
    fontFamily: 'cardiuma-SemiBold !important',
    fontSize: '16px !important',
    fontStyle: 'normal !important',
    fontWeight: '600 !important',
    lineHeight: '18px !important',

},
buttoncard: {
    width: '186px',
    height: '42px',
    flexShrink: 0,
    borderRadius: '8px ! important',
    border: '1px solid #DFE0EB ! important',
    backgroundColor: '#FFFFFF'


},

buttoncard1: {
    display: 'flex',
    width: '194px',
    height: '48px',
    padding: '8px 24px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    flexShrink: 0,
    borderRadius: '27px',
    border: '1px solid #E9EAF1',
    background: '#FFF'
},
buttontext: {
    display: 'flex',
    width: '186px',
    height: '23px',
    flexDirection: 'column ',
    marginLeft: '10px',
    marginTop: '4px',
    flexShrink: 0,
    color: '#3E3E3E',
    textAlign: 'center ! important',
    fontFamily: 'cardiuma-regular !important',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'normal'
},
cancelbutton: {
    color: '#F74542 ! important',
    fontFamily: 'cardiuma-M ! important',
    fontSize: '18px ! important',
    fontStyle: 'normal ! important',
    fontWeight: '500 ! important',
    lineHeight: '22px ! important',
    width: '194px',
    display: 'flex',

    height: '48px',
    padding: '8px 24px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '27px ! important',
    border: '1px solid #E9EAF1 ! important',
    gap: '100px ! important',
    marginRight: '2px ! important',
    flexShrink: 0,
    textTransform: "initial !important",
},

submitbutton: {
    width: '194px',
    display: 'flex',

    height: '48px',
    padding: '8px 24px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    flexShrink: 0,
    color: '#FFF ! important',
    fontFamily: 'cardiuma-M ! important',
    fontSize: '18px ! important',
    fontStyle: 'normal ! important',
    fontWeight: '500 ! important',
    lineHeight: '22px ! important',
    
    borderRadius: '27px ! important',
    background: '#F74542 ! important',
    marginRight: '2px ! important',
    textTransform: "initial !important",

},
basicpoints: {
    color: '#FFF',
    fontFamily: 'cardiuma-regular !important',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '22px' /* 157.143% */
},

moretext: {
    color: '#F74542',
    fontFamily: 'cardiuma-M !important ',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '16px',
    marginLeft: '20px'
},

checkboxcard: {

    width: '24px ! important',
    marginTop: '5px  ! important',
    height: '24px  ! important',
    flexShrink: '0',
    //color:'yellow ! important'



},

datetext: {

    width: '250px', height: '22.561px',
    flexShrink: 0,
    color: '#0B1420 ! important',
    fontFamily: 'cardiuma-regular !important',
    fontSize: '16px',
    marginLeft: '5px',
    lineHeight: 'normal ',
    fontStyle: 'normal',
    fontWeight: '400',

},
refcard_txt:{
    fontStyle: "normal !important",
    fontWeight: "550 !important",
    fontSize: "40px !important",

    color: "#FFFFFF !important",
    textTransform: "initial !important",
    fontFamily: "cardiuma-SemiBold !important",
    // marginBlockStart: 0, 
    // marginBlockEnd: 0,
    
    "@media screen and (max-width:960px)": {

        fontSize: "28px !important",
    }
},
refcard_txt1:{
    fontStyle: "normal !important",
    fontWeight: "250 !important",
    fontSize: "24px !important",
lineHeight:"33px !important",
    color: "#FFFFFF !important",
    textTransform: "initial !important",
    fontFamily: "cardiuma-regular !important",
    
    "@media screen and (max-width:960px)": {

        fontSize: "18px !important",
    }
},
refcard_txt2:{
    fontStyle: "normal !important",
    fontWeight: "200 !important",
    fontSize: "24px !important",
lineHeight:"33px !important",
    color: "#FFFFFF !important",
    textTransform: "initial !important",
    fontFamily: "cardiuma-SemiBold !important",
    "@media screen and (max-width:960px)": {

        fontSize: "18px !important",
    }
},
refcard_txt3:{
    fontStyle: "normal !important",
    fontWeight: "200 !important",
    fontSize: "18px !important",
lineHeight:"33px !important",
    color: "#FFFFFF !important",
    textTransform: "initial !important",
    fontFamily: "cardiuma-regular !important",
    "@media screen and (max-width:960px)": {

        fontSize: "14px !important",
    }
},
reflinktxt:{
    fontStyle: "normal !important",
    fontWeight: "200 !important",
    fontSize: "18px !important",
lineHeight:"24px !important",
    color: "#242424 !important",
    textTransform: "initial !important",
    fontFamily: "cardiuma-regular !important",
    "@media screen and (max-width:960px)": {

        fontSize: "14px !important",
    }
},
referral_but: {
    
    padding: '12px 45px !important',
    borderRadius: '24px !important',
    fontStyle: 'normal !important',
    fontWeight: '600 !important',
    fontSize: '18px !important',
    lineHeight: '18px !important',
    color: '#FFFFFF !important',
    textTransform: "initial !important",

    fontFamily: 'cardiuma-regular !important',
    "@media screen and (max-width:960px)": {
marginLeft:'20px',
        fontSize: "14px !important",
    },
border:'1px solid #FFFFFF !important',
"&:hover": {
    background: "#FFFFFF !important",
    
    
    
    
    

    color: "#F74542 !important",
    textTransform: "initial !important",
    
    border: '1px solid #FFFFFF !important',

},
    
},
referral_but2: {
    
    padding: '12px 45px !important',
    borderRadius: '24px !important',
    fontStyle: 'normal !important',
    fontWeight: '100 !important',
    fontSize: '18px !important',
    lineHeight: '18px !important',
    color: '#F74542 !important',
    textTransform: "initial !important",
    background: "#FFFFFF !important",
    fontFamily: "cardiuma-SemiBold !important",
border:'1px solid #FFFFFF !important',
"@media screen and (max-width:960px)": {

    fontSize: "14px !important",
}
   
},
referral_but1: {
    
    padding: '5px !important',
    borderRadius: '14px !important',
    fontStyle: 'normal !important',
    fontWeight: '500 !important',
    fontSize: '12px !important',
    lineHeight: '18px !important',
    color: '#FFFFFF !important',
    textTransform: "initial !important",

    fontFamily: 'cardiuma-regular !important',
border:'1px solid #FFFFFF !important',
"@media screen and (max-width:960px)": {

    fontSize: "14px !important",
},
"&:hover": {
    background: "#FFFFFF !important",
    
    borderRadius: "14px !important",
    fontStyle: "normal !important",
    
    fontSize: "12px !important",

    color: "#F74542 !important",
    textTransform: "initial !important",
    fontFamily: "cardiuma-Bold !important",
    border: '1px solid #FFFFFF !important',

},
   
},
ref_card:{
    width:'45%',marginLeft:'-70px', display:'flex',justifyContent:'flex-end' ,
    "@media screen and (max-width:960px)": {
        display:'none'
    }
},
ref_card1:{
width:'50%',marginLeft:'5%', padding:'40px 0px 0px 0px',
"@media screen and (max-width:960px)": {
    padding:'16px 38px 16px 16px',
    width:'100%',
}
},
ref_card2:{
    width:'70%',
    "@media screen and (max-width:960px)": {
        
        width:'90%',
    }
},
ref_card3:{

width:'70%',display:'flex',alignItems:'center',background:'#ffffff',padding:'5px',borderRadius:'6px',marginTop:'5%',
"@media screen and (max-width:960px)": {
        
    width:'100%',
    marginBottom:'25px'
}
},
ref_button_card:{

    marginTop:'10%',width:'70%',display:'flex',justifyContent:'space-evenly',
    "@media screen and (max-width:960px)": {
        
        width:'90%',
        marginBottom:'25px'
    }
},
ref_card5:{
    width:'100%',display:'flex',alignContent:'flex-end',
    "@media screen and (max-width:960px)": {
        
        width:'90%',
    }
},
ref_card6:{
width:'100%',display:'flex',alignContent:'flex-end',
"@media screen and (max-width:960px)": {
        
    width:'90%',
}
},
ref_card7:{
    width: '100%', marginTop: '5px',
    "@media screen and (max-width:960px)": {
        
        width:'100%',
    }   
},
chat_card:{
    width:'100%',marginTop:'10px',
    "@media screen and (max-width:960px)": {
        
        width:'96%',
    }  
},
chat_card1:{
    width:'100%',
    "@media screen and (max-width:960px)": {
        
        width:'96%',
    } 
},

mobileLandingImg : {
    zIndex: '1',
    height: '75vh',
    width: '100vw',
    "@media screen and (max-width:500px)": {
        height: '450px'
    } ,
    "@media screen and (max-width:450px)": {
        height: '377px'
    } 
},
/*dev-Ayush 13-07-2024 */
newMobileLandingPic : {
    width : "100%",
    marginTop : "50px",
    backgroundColor : "#1B2636",
    display : "flex",
    alignItems : "end",
    "@media screen and (min-width:430px)": {
        display : "none"
    } 

},
newMobileLandingPic1 : {
    width : "100%",
    marginTop : "50px",
    backgroundColor : "#1B2636",
    display : "flex",
    justifyContent : "center",
    alignItems : "end",
    "@media screen and (max-width:430px)": {
        display : "none"
    },
    "@media screen and (min-width:960px)": {
        display : "none"
    } 
},

//added by dev-Ayush , dev-srini , expertCard styles //start
expertCardText : {
    fontSize : "35px !important",
    fontFamily : "cardiuma-SemiBold !important",
    "@media screen and (max-width:1450px)": {
        fontSize : "28px !important",
    },
    "@media screen and (max-width:1200px)": {
        fontSize : "2.3vw !important",
    },
    "@media screen and (max-width:680px)": {
        fontSize : "15.5px !important",
    }
},

expertCardText1 : {
    fontSize : "18px !important",
    fontFamily : "cardiuma-SemiBold !important",
    color : "#ffffff !important",
    textTransform : "capitalize !important",
    "@media screen and (max-width:1200px)": {
        fontSize : "13px !important",
    },
    "@media screen and (max-width:800px)": {
        fontSize : "12px !important",
    } ,
},



expertCardExploreButton : {
    backgroundColor: "#F74542 !important",
    
    borderRadius : "27px !important",
    padding :"8px 24px 8px 24px !important",
    marginBottom : "15px !important",
    marginLeft : "30px !important",
    "@media screen and (max-width:1200px)": {
        padding :"6px 16px 6px 16px !important",
        marginBottom : "10px !important"
    } ,
    "@media screen and (max-width:1000px)": {
        padding :"5px 8px 5px 8px !important",
        marginBottom : "5px !important",
        marginLeft : "15px !important"
    },
    "@media screen and (max-width:800px)": {
        marginLeft : "5px !important"
    } 
},

expertGirl : {
    marginLeft : "3vw !important",
    width : "auto !important",
    height : "634px !important",
    marginBottom : "-4px !important",
    "@media screen and (max-width:1600px)": {
        height : "600px !important",
        marginLeft : "0px !important"
    } ,
    "@media screen and (max-width:1450px)": {
        height : "525px !important",
    },
    "@media screen and (max-width:1200px)": {
        height : "40vw !important",
    }    
}
,expertCard : {
    height : "319px !important",
    "@media screen and (max-width:1600px)": {
        width : "55vw !important",
        height : "auto !important",
        marginRight : "30px !important"
    },
},
expertSubCard : {
    position : "absolute !important",
    top : "100px !important",
    left : "45vw !important",
    "@media screen and (max-width:1600px)": {
        top : "50px !important",
        left : "42vw !important"
    },
    "@media screen and (max-width:1450px)": {
        top : "50px !important",
        left : "40vw !important",
    },
    "@media screen and (max-width:1000px)": {
        left : "40vw !important"
    }  ,
    "@media screen and (max-width:1200px)": {
        top : "30px !important"
    },
    
},
expertCardGrid : {
    width: "100% !important",
    background: "#ffffff !important",
    display : "flex !important",
    alignItems : "end !important" ,
    paddingRight : "30px !important" ,
    "@media screen and (max-width:600px)": {
        display : "none !important"
    } 
},
mimgwdv : { 
    width: "65%",
    display: "block", 
    margin: "0 auto",
    "@media (max-width:500px)" : {
        width: "80%"
    },
},

mtxtbxv : {
    width: "95%",
    backgroundColor: "#000",
    borderRadius: "11px",
    position: "absolute",
    top: "90%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
    zIndex: "1",
},

mweffrtxv : {
    color: "#fff",
    fontFamily: "cardiuma-regular !important",
    fontSize: "20px !important",
    fontWeight: "600 !important",
    letterSpacing: "1px !important",
    padding: "15px",
    "@media (max-width:480px)" : {
        fontSize: "17px !important"
    },
    "@media (max-width:480px)" : {
        fontSize: "15px !important"
    },
    "@media (max-width:380px)" : {
        fontSize: "12.5px !important"
    },  
},

mexplnwbtn :{
    width: "157px !important",
    height: "38px !important",
    borderRadius: "27px !important",
    backgroundColor: "#F74542 !important",
    position: "absolute",
    top: "32px" ,
    left: "50%",
    transform: "translate(-50%, 0)",
    zIndex: 2,
    "@media (max-width:386px)" : {
        top: "70px !important",
    },
    "@media (max-width:500px)" : {
        top: "30px !important"
    },
},

mbtntxv :{
    color: "#fff",
    fontFamily: "cardiuma-regular !important",
    fontSize: "16px !important",
    fontWeight: "700 !important",
    textTransform: "none !important",
}
,
mimgwdv1 :{
    width: "85%" ,
    display: "block", 
    margin: "0 auto",
    marginBottom : "20px",
    "@media (max-width:440px)" : {
         marginBottom : "10px"
    },
},

expertmainGrid : {
    minHeight: '100vh',
    "@media (min-width:601px)" : {
       display : "none"
    }
}
// added by dev-Ayush , dev-srini , expertCard styles //end


});

const snackeror = {
    myerror: {
        backgroundColor: "#ffffff",
        borderLeft: "8px solid #b60000",
        flexWrap: "nowrap",
        color: "#005100",
        borderTop: "1px solid #b60000",
        borderRight: "1px solid #b60000",
        borderBottom: "1px solid #b60000",
    },
    mysucc: {

        backgroundColor: "#ffffff",
        borderLeft: "8px solid #8db600",
        flexWrap: "nowrap",
        color: "#005100",
        borderTop: "1px solid #8db600",
        borderRight: "1px solid #8db600",
        borderBottom: "1px solid #8db600",
    },
};



class landingpage extends Component {


    constructor(props) {
        super(props)


        this.state = {
            mysnack: snackeror.mysucc,
            showerr: false,
            errorMsg: '',
            fname: '',
            lname: '',
            screenh: 0,
            screenw: 0,
            email: '',
            errtype: 0,
            applay_page: 0,
            conn_name: '',
            conn_email: '',
            conn_phone: '',
            incub_page: 0,
            incub_name: '',
            incub_email: '',
            incub_phone: '',
otp:'',
//Raviteja - 2023/10/16 ,lines 1396

name: '',
mobilenumber: '',
minDate: dayjs(),
isHoveredHindi: false,
isHoveredEnglish: false,
isHoveredslot1: false,
isHoveredslot2: false,
selectDate: null,
selectLanguage: '',
cleared: false,

selectSlot: '',
selectPlan: '',
defaultBasicCheckBox: true,
defaultProfessionalCheckBox: true,
defaultCompleteCheckBox: true,
datemodal: false,
islabel: false,
basic: [
    'Introduction',
    'Swing Trading v/s Day Trading',
    'How part-time trading works',
    'Finding the best stock to Swing Trade',
    'Stock screening criteria',
    'Analyzing your stocks',
    'Stock analysis websites',
    'When to enter your position',
    'When to exit your position',
    'Trading platform overview and features'
],
professional: [
    'Trading strategies based on technical indicators',
    'Trading strategies based on patterns',
    'Trading with special patterns',
    'Trading strategies based on Elliot Wave',
    'Fibonacci trend line trading strategy',
    'Trading strategies based on Options strategies',
    'Trading strategy based on | Support resistance | Harmonic patterns | Gan fan concepts |  Candlestick pattern',
    'Trading strategy based on commodity',
    'Pivot points, Fibonacci, Trend line and time frame used by trader',
    'Trading strategies based on Forex',
    'Stock selection and entry exit criteria for intraday trading',
    'Trading phycology and qualities of good trader',
    'Stock selection and entry exit criteria for intraday trading',
    'Trading phycology and qualities of good trader'
],
complete: [
    'Course Introduction',
    'Options and the Stock Volatility, the VIX and more',
    'The basics of how Options work (Tickers, Call, Put and more)',
    'Pricing a Long or Short Call or Put',
    'Valuation and Analysis of Options',
    'Statistics and Greek formulas to analyze options',
    'How to value options using Binomial pricing',
    'How to value Options using Black Scholes',
    'How to value Options using Monte Carlo simulation',
    'Option Strategies',
    'Creating single option strategies',
    'Creating 1 Stock PLUS Single option strategies',
    'Creating single option strategies',
    'Creating 1 Stock PLUS Single option strategies'
],
showModal: false,
ref_page:0,
clentid:'',
reflink:'',
copytxt:'Copy'

//Raviteja -2023/10/16 -End -line 1459
        }



    }
CopyLink()
{
    navigator.clipboard.writeText(this.state.reflink);
    this.setState({copytxt:'Copied'})
}
    componentDidMount = async () => {

        document.body.style.backgroundColor = "#0B1420"

        localStorage.removeItem('reg_data')
      
      
        if (isMobile) {
            window.Changemytab(3)
        } else {
            window.Changemytab(0)
        }

        window.GotoFrancey = () => {
            

            var ele = document.getElementById('franchisee');   
window.scrollTo(ele.offsetLeft,ele.offsetTop)
        }
    }

    IncubSubmit = async () => {

        const { incub_name, incub_email, incub_phone } = this.state
        let isok = 0

        if (!incub_name.length && isok === 0) {

            isok = 1

            this.setState({ errtype: 21, errorMsg: "Enter the  Name" })

        }

        if (!incub_phone.length && isok === 0) {

            isok = 1
            this.setState({ errtype: 23, errorMsg: "Enter the  Phone Number" })

        }

        if(validator.isMobilePhone(incub_phone, 'en-IN')=== false && isok === 0 ){

            isok = 1
            this.setState({ errtype: 23, errorMsg: "Enter valid Phone Number"})

        }


        if (!incub_email.length && isok === 0) {

            isok = 1
            this.setState({ errtype: 22, errorMsg: "Enter the E-Mail" })

        }

        if (validator.isEmail(incub_email) === false && isok === 0) {

            isok = 1
            this.setState({ errtype: 22, errorMsg: "E-Mail Not Vailed" })

        }







        if (isok === 0) {

            let payload = {
                name: incub_name,
                email: incub_email,
                mobileno: incub_phone
            }

            try {
                const openaccount = await httpRequest({
                    endPoint: `api/user_form/incubsubmit`,
                    method: "post",
                    instance: "instanceOne",
                    requestBody: payload,
                });
                console.log(openaccount.status)
                if (openaccount.status === 200) {

                    this.setState({
                        showerr: true, errorMsg: 'Your request has been submitted for review.', mysnack: snackeror.mysucc,

                        incub_name: '', incub_email: '', incub_phone: '', incub_page: 0
                    })

                } else {
                    this.setState({ showerr: true, errorMsg: openaccount.message, mysnack: snackeror.myerror })
                }
            } catch (error) {
                this.setState({ showerr: true, errorMsg: "oops something went wrong", mysnack: snackeror.myerror })
            }

        }

    }

    cancelResetIncub = () =>{
        this.setState({incub_name:"", incub_email:"", incub_phone:"" })
    }

    ConnSubmit = async () => {

        const { conn_name, conn_email, conn_phone } = this.state
        let isok = 0

        if (!conn_name.length && isok === 0) {

            isok = 1

            this.setState({ errtype: 11, errorMsg: "Enter the  Name" })

        }

        if (!conn_phone.length && isok === 0) {

            isok = 1
            this.setState({ errtype: 13, errorMsg: "Enter the  Phone Number" })

        }

        if (validator.isMobilePhone(conn_phone, 'en-IN') === false && isok === 0){
            isok = 1
            this.setState({errtype: 13, errorMsg: "Enter vaild Phone Number"})
        }

        if (!conn_email.length && isok === 0) {

            isok = 1
            this.setState({ errtype: 12, errorMsg: "Enter the E-Mail" })

        }

        if (validator.isEmail(conn_email) === false && isok === 0) {

            isok = 1
            this.setState({ errtype: 12, errorMsg: "E-Mail Not Vailed" })

        }







        if (isok === 0) {

            let payload = {
                name: conn_name,
                email: conn_email,
                mobileno: conn_phone
            }

            try {
                const openaccount = await httpRequest({
                    endPoint: `api/user_form/connsubmit`,
                    method: "post",
                    instance: "instanceOne",
                    requestBody: payload,
                });
                console.log(openaccount.status)
                if (openaccount.status === 200) {

                    this.setState({
                        showerr: true, errorMsg: 'Your request has been submitted for review.', mysnack: snackeror.mysucc,

                        conn_name: '', conn_email: '', conn_phone: '', applay_page: 0
                    })

                } else {
                    this.setState({ showerr: true, errorMsg: openaccount.message, mysnack: snackeror.myerror })
                }
            } catch (error) {
                this.setState({ showerr: true, errorMsg: "oops something went wrong", mysnack: snackeror.myerror })
            }

        }

    }

    cancelResetConn = () => {
        this.setState({conn_name:"", conn_email:"", conn_phone:"" })
    }

    OpenTradeingAccount = async () => {

        const { fname, lname, email } = this.state

        let isok = 0

        if (!fname.length && isok === 0) {

            isok = 1

            this.setState({ errtype: 1, errorMsg: "Enter the  Name" })

        }

        if (!lname.length && isok === 0) {

            isok = 1
            this.setState({ errtype: 2, errorMsg: "Enter the  Phone Number" })

        }
        
        if(validator.isMobilePhone(lname, 'en-IN') === false && isok ===0){

            isok = 1
            this.setState({ errtype: 2, errorMsg: "Enter vaild Phone Number" })

        }


        if (!email.length && isok === 0) {

            isok = 1
            this.setState({ errtype: 3, errorMsg: "Enter the E-Mail" })

        }

        if (validator.isEmail(email) === false && isok === 0) {

            isok = 1
            this.setState({ errtype: 3, errorMsg: "E-Mail Not Vailed" })

        }



        const logininfo = {
            email: email,
            mobileno: lname

        }

        localStorage.setItem('signup', JSON.stringify(logininfo))

        if (isok === 0) {

            let payload = {
                fname: fname,
                lname: lname,
                email: email
            }

            try {
                const openaccount = await httpRequest({
                    endPoint: `api/user_form/openaccount`,
                    method: "post",
                    instance: "instanceOne",
                    requestBody: payload,
                });
                console.log(openaccount.status)
                if (openaccount.status === 200) {

                    this.setState({
                        showerr: true, errorMsg: 'Your request has been submitted for review.', mysnack: snackeror.mysucc,

                        fname: '', lname: '', email: ''
                    })
                    this.props.history.push('/Signup', this.state)
                } else {
                    this.setState({ showerr: true, errorMsg: openaccount.message, mysnack: snackeror.myerror })
                }
            } catch (error) {
                this.setState({ showerr: true, errorMsg: "oops something went wrong", mysnack: snackeror.myerror })
            }

        }




    }


   

 //Raviteja - 2023/10/16 ,start line-1568

 submitJoiningForm = async () => {

    const { name, email, mobilenumber, selectDate, selectLanguage,  selectSlot, } = this.state
    let isok = 0

    if (this.state.defaultBasicCheckBox) {

        this.state.selectPlan = 'Basic'

    }
    else if (this.state.defaultProfessionalCheckBox) {

        this.state.selectPlan = 'Professional'
    }
    else {

        this.state.selectPlan = 'Complete'
    }

    if (!name.length && isok === 0) {

        isok = 1
        this.setState({ errtype: 1, errorMsg: "Enter your Name" })


    }
    if (!email.length && isok === 0) {

        isok = 1
        this.setState({ errtype: 2, errorMsg: "Enter valid Email" })

    }

    if (validator.isEmail(email) === false && isok === 0) {

        isok = 1
        this.setState({ errtype: 2, errorMsg: "Enter valid Email" })

    }
    if (!mobilenumber.length && isok === 0) {

        isok = 1
        this.setState({ errtype: 3, errorMsg: "Enter Phone Number" })

    }
    if(validator.isMobilePhone(mobilenumber, 'en-IN') === false && isok === 0){

        isok = 1
        this.setState({ errtype:3, errorMsg:"Enter valid Phone Number"})

    }

    if (validator.isDate(selectDate) === false && isok === 0) {

        isok = 1
        this.setState({ errtype:7, errorMsg:"Select the date"})

    }

    if (!selectLanguage.length && isok === 0) {

        isok = 1
        this.setState({ errtype: 4, errorMsg: "select Language" })

    }

    if (!selectSlot.length && isok === 0) {

        isok = 1
        this.setState({ errtype: 5, errorMsg: "select slot" })

    }

    if (!this.state.selectPlan.length && isok === 0) {
        isok = 1;
        this.setState({ errtype: 6, errorMsg: "select plan" });
    }

    if (!selectDate.length && isok === 0) {
        console.log('coming', selectDate)
        isok = 1
        this.setState({ errtype: 7, errorMsg: "select date" })

    }
    console.log(this.state.selectPlan, selectDate, selectLanguage, name, email, mobilenumber, selectSlot)

    if (isok === 0) {

        let payloadone = {

            name: name,
            email: email,
            phone: mobilenumber,
            tdate: selectDate,
            lang: selectLanguage,
            slots: selectSlot,
            coursename: this.state.selectPlan
        }



        try {
            const joiningform = await httpRequest({
                endPoint: `api/training/submit`,
                method: "post",
                instance: "instanceOne",
                requestBody: payloadone,
            });
            if (joiningform.status === 200) {

                this.setState({
                    showerr: true, errorMsg: "Your request is Saved successfully", mysnack: snackeror.mysucc,
                    name: '', email: '', mobilenumber: '', selectLanguage: '', selectSlot: '', selectPlan: '', selectDate: '',
                    defaultBasicCheckBox: true, defaultProfessionalCheckBox: true, defaultCompleteCheckBox: true
                })

            }

        } catch (error) {
            this.setState({ showerr: true, errorMsg: "oops something went wrong", mysnack: snackeror.myerror })

        }

    }

}
isPastDate = (date) => {
    const today = dayjs()
    return date.isBefore(today, 'day');
};



handleChange = (date) => {
    const formattedDate = date.format('YYYY/MM/DD')

    this.setState({
        minDate: dayjs(),
        islabel: true,
        selectDate: formattedDate,
        errtype: 0,
        datemodal: false,
    });
};


handleBasicPlan = (plan) => {
    this.setState({


        defaultBasicCheckBox: plan === 'Basic' ? false : true
    })
    console.log(this.state.selectPlan, 'sp')
}

handleProfessionalPlan = (plan) => {
    this.setState({


        defaultProfessionalCheckBox: plan === 'Professional' ? false : true
    })
}

handleCompletePlan = (plan) => {
    this.setState({


        defaultCompleteCheckBox: plan === 'Complete' ? false : true
    })
}

closeContactModal = () => {
    this.setState({ showerr: false })
}

openModal = () => {
    this.setState({ showModal: true });
}

closeModal = () => {
    this.cancelReset()
    this.setState({ showModal: false })
}

openDateModal = async () => {
    this.setState({ datemodal: true });



}

VerifyReferralOTP= async () => 
    {

        const {clentid,otp}=this.state
        let payload = {
            clentid: clentid,
            otp:otp
           }   

           try {
            const openaccount = await httpRequest({
                endPoint: `/api/referral/otpvalidate`,
                method: "post",
                instance: "instanceTwo",
                requestBody: payload,
            });
            if(openaccount.status===200)
            {
                    
                    let reflink=  backendHosts.QR +"signup?referral="+openaccount.referral_code
                this.setState({ref_page:3,reflink:reflink})
            }
            if(openaccount.status===201)
            {
                this.setState({ showerr: true, errorMsg: openaccount.message, mysnack: snackeror.myerror })
            }
            
           } catch (error) {
            
           }
        
           

    
}
VerifyReferral= async () => {
   const {clentid}=this.state
   let payload = {
    clentid: clentid
   }   
try {
    const openaccount = await httpRequest({
        endPoint: `api/referral/verify`,
        method: "post",
        instance: "instanceTwo",
        requestBody: payload,
    });
    if(openaccount.status===200)
    {

        let reflink=  backendHosts.QR +"signup?referral="+openaccount.referral_code
        
        this.setState({ ref_page:2 ,email:openaccount.emailid,mobilenumber:openaccount.mobileno})
        this.setState({ showerr: true, errorMsg: openaccount.message, mysnack: snackeror.mysucc })
    }
    if(openaccount.status===201)
    {
        this.setState({ errtype:50, showerr: true, errorMsg: openaccount.message, mysnack: snackeror.myerror })
    }
} catch (error) {
    
}


}

cancelResetReferral = () =>{
    this.setState({clentid:""})
}



// Event handler to close the modal
closeDateModal = () => {
    this.setState({ datemodal: false });
}
handleOTPChange = (otp) => {
    this.setState({ otp })


}
isDateEnabled = (date) => {
    const today = dayjs();
    return date.isSameOrAfter(today, 'day');
}

cancelReset = () => {
    this.setState({
        name: '', email: '', mobilenumber: '', selectDate: '', selectLanguage: '',
        selectSlot: '', selectPlan: '', defaultBasicCheckBox: true,
        defaultProfessionalCheckBox: true, defaultCompleteCheckBox: true,showModal:false
    })

}

wealthVaultButton = () =>{
    window.open(backendHosts.WEALTH_VAULT_URL)
}
//Raviteja - 2023/10/16 -End -line 1767

    render() {
        const { classes } = this.props;
 //Raviteja - 2023/10/16 , start line-> 1773

 //Raviteja -2023/10/16 ,End line->1775
        return (

            <div style={{ height: 'auto' }}>
                {/* added on 01/09/24   */}
 
                {/* <Grid className={classes.mobile_manin1} style={{}} >
                    <Grid  style={{ position: 'relative', }}>
                        <img src={landing2_mob} alt="main" className={classes.mobileLandingImg}  >


                            
                        </img>

                      
                    </Grid>
                </Grid > */}

                {/*dev-ayush 13-07-2024 */}
                    <Grid className={classes.newMobileLandingPic}>
                    <img src={newMobilePic} style={{width : "100%" , height : "auto" , marginTop : "50px"}}/>
                    </Grid>
                    <Grid className={classes.newMobileLandingPic1}>
                    <img src={newMobilePic1} style={{width : "100%" , height : "auto", marginTop : "50px"}}/>
                    </Grid>
                {/*dev-ayush */}

                <Grid Container className={classes.mobile_manin} >
                    <Carousel left
                        showThumbs={false}
                        stopOnHover={false}
                        showArrows={false}
                        autoPlay={true}
                        interval={3500}
                        infiniteLoop={true}
                        swipeable={false}

                    >
                        <div>
                            <img  src="./ui-assests/images/landing1.webp"   alt="landing1" style={{ height: '100vh' ,width:'100%'}} />

                        </div>
                        <div>
                            <img src="./ui-assests/images/landing2.webp" alt="landing2"  style={{ height: '100vh' ,width:'100%'}} />

                        </div>
                        <div>
                            <img src="./ui-assests/images/landing3.webp" alt="landing3"  style={{ height: '100vh' ,width:'100%'}} />

                        </div>
                        <div>
                            <img src="./ui-assests/images/landing4.webp" alt="landing4" style={{ height: '100vh' ,width:'100%'}} />

                            </div>

                    </Carousel>

                </Grid>

                


                <Grid
                    container
                    className={classes.openaccountcard}
                >
                    {/* mui typography replaced with html h1 tag - 02/09/24 - Dev Srini */}
                    <Grid className={classes.openaccountcard_1}>
                        <h1 className={classes.openaccount}>
                            Open Trading Accounts
                        </h1>
                        <Typography className={classes.openaccountsub}>
                            Experience the fusion of technology and traditional trading.
                        </Typography>

<div className={classes.openaccounting} >
                        <img src={openAccPC} className={classes.chat_card1} alt="logo" ></img>
                        <div className={classes.chat_card}
                    
                    onClick={(e) => window.open("https://www.tradingview.com/")}
                        >
                             <Typography className={classes.openaccountsub1} >Charts powered by 
                        <span className={classes.openaccountsub2}> Tradingview</span></Typography>
                       
                            </div>
                            </div>
                       
                    </Grid>
                    <Grid className={classes.formCard_grid} id='fillform' >
                        <Card className={classes.formCard}
                        >
                            <div style={{ paddingBottom: "15px" }}>
                                <FormControl style={{ width: '100%' }} variant="standard">
                                    <TextField size="medium"
                                    variant={ window.innerWidth <= 960 ? "outlined":"standard"}
                                   
                                    className={classes.openTradingAccForm}
                                        // className={classes.autofill_fix}
                                        label={this.state.fname ? "" : 'Name'}
                                        fullWidth

                                        value={this.state.fname}
                                        sx={{
                                            '& .MuiInput-underline:before': { borderBottomColor: '#92A4C1' },
                                            '& .MuiInput-underline:after': { borderBottomColor: '#BCD171' },

                                        }}
                                        InputLabelProps={{
                                            style: { color: this.state.errtype === 1 ? "#F74542" : '#92A4C1', fontFamily: 'cardiuma-regular !important', fontSize: '12px' },
                                            shrink : false
                                        }}
                                        InputProps={{
                                            classes: { input: classes.input1
                                             },
                                            maxLength: 16,
                                          
                                            style: { color: window.innerWidth <= 960 ?"#000000":"#0B1420", fontFamily: 'cardiuma-regular !important', fontSize: '18px', height: '30px', },

                                        }}
                                        onChange={(e) => this.setState({ fname: e.target.value, errtype: 0 })}
                                    ></TextField>
                                    {this.state.errtype === 1 &&
                                        <FormHelperText className={classes.errtxt} id="component-error-text">{this.state.errorMsg}</FormHelperText>
                                    }
                                </FormControl>
                            </div>
                            <div style={{ paddingBottom: "15px" }}>
                                <FormControl style={{ width: '100%' }} variant="standard">
                                    <TextField size="medium" variant={ window.innerWidth <= 960 ? "outlined":"standard"}


className={classes.openTradingAccForm}
                                        label={this.state.lname ? "" :'Phone No'}
                                        fullWidth

                                        value={this.state.lname}
                                        sx={{
                                            '& .MuiInput-underline:before': { borderBottomColor: '#92A4C1' },
                                            '& .MuiInput-underline:after': { borderBottomColor: '#BCD171' },


                                        }}
                                        InputLabelProps={{
                                            style: { color: this.state.errtype === 2 ? "#F74542" : '#92A4C1', fontFamily: 'cardiuma-regular !important', fontSize: '12px' },
                                            shrink : false

                                        }}
                                        InputProps={{
                                            classes: { input: classes.input1 },
                                            maxLength: 16,
                                            style: { color: window.innerWidth <= 960 ?"#000000":"#0B1420",  fontFamily: 'cardiuma-regular !important', fontSize: '18px', height: '30px', },

                                        }}
                                        onChange={(e) => this.setState({ lname: e.target.value, errtype: 0 })}
                                    ></TextField>
                                    {this.state.errtype === 2 &&
                                        <FormHelperText className={classes.errtxt} id="component-error-text">{this.state.errorMsg}</FormHelperText>
                                    }
                                </FormControl>
                            </div>
                            <div style={{ paddingBottom: "25px" }}>

                                <FormControl style={{ width: '100%' }} variant="standard">
                                    <TextField size="medium" variant={ window.innerWidth <= 960 ? "outlined":"standard"}


                                        className={classes.openTradingAccForm}
                                        label={this.state.email ? "" : 'Email'}
                                        fullWidth
 
                                        value={this.state.email}
                                        sx={{
                                            '& .MuiInput-underline:before': { borderBottomColor: '#92A4C1' },
                                            '& .MuiInput-underline:after': { borderBottomColor: '#BCD171' },


                                        }}
                                        InputLabelProps={{
                                            style: { color: this.state.errtype === 3 ? "#F74542" : '#92A4C1', fontFamily: 'cardiuma-regular !important', fontSize: '12px' },
                                            shrink : false 

                                        }}


                                        InputProps={{
                                            maxLength: 16,
                                            style: { color: window.innerWidth <= 960 ?"#000000":"#0B1420",  fontFamily: 'cardiuma-regular !important', fontSize: '18px', height: '30px', },

                                            classes: { input: classes.input }
                                        }}

                                        onChange={(e) => this.setState({ email: e.target.value })}
                                    ></TextField>
                                    {this.state.errtype === 3 &&
                                        <FormHelperText className={classes.errtxt} id="component-error-text">{this.state.errorMsg}</FormHelperText>
                                    }
                                </FormControl>
                            </div>
                            <Button
                                style={{ backgroundColor: "#F74542" }}
                                fullWidth
                                className={classes.opentradeBtn}
                                onClick={() => this.OpenTradeingAccount()}
                            >
                                Open Trading Accounts
                            </Button>
                        </Card>
                    </Grid>


                </Grid>

                {/* expert card start 25/09/2024 //dev-Ayush //dev-Srini*/}

                    {/*mobile*/}
                    <Grid bgcolor='#fff' className={expertCardStyles.expertmainGrid}>
                    <Grid container justifyContent="center" alignItems="center" sx={{ position: "relative" }}>
                        <Grid item xs={12} mt="40px">
                            <img src={image} alt='image' className={expertCardStyles.mimgwdv} />
                        </Grid>

                        <Grid item xs={12}>
                            <Box className={expertCardStyles.mtxtbxv}>
                                <Typography className={expertCardStyles.mweffrtxv} align="center">
                                We are the platform for Expert Advice <br />
                                from SEBI registered advisors
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item >
                            <Button onClick={()=>window.open(backendHosts.EXPURL)} className={expertCardStyles.mexplnwbtn}>
                                <Typography  className={expertCardStyles.mbtntxv}>Explore More</Typography>
                            </Button>
                        </Grid>
                    </Grid>

                    <Grid container justifyContent="center" alignItems="center" mt='60px'>
                        <Grid item mb='15px'>
                            <img src={image2} alt='image' className={expertCardStyles.mimgwdv1} />
                        </Grid>
                    </Grid>
                </Grid>

                {/*tab and laptop */}                    
                <Grid className={expertCardStyles.expertCardGrid}>
                <Grid display="flex" alignItems="end !important" justifyContent="center" position="relative" mt = "50px">
                    <Grid >
                    <img src={expertGirl} className={expertCardStyles.expertGirl}/>
                    </Grid>
                        <Grid className={expertCardStyles.expertSubCard} >
                            <Grid display="flex"  alignItems="end" >
                                <Grid >
                                <Typography className={expertCardStyles.expertCardText}>We are the platform for Expert Advice 
                            </Typography>
                            <Typography className={expertCardStyles.expertCardText}>
                            from SEBI registered advisors <Button onClick={()=>window.open(backendHosts.EXPURL)} className={expertCardStyles.expertCardExploreButton}><Typography className={expertCardStyles.expertCardText1}>Explore more
                                </Typography>
                            </Button>  
                            </Typography>
                                </Grid>
                        </Grid>
                    <img src={expertCardImg} className={expertCardStyles.expertCard}/>
                    </Grid>
                  
                </Grid>
            </Grid>
            {/* expert card end 25/09/2024 //dev-Ayush //dev-Srini*/}

                {/* start // wealth vault grid dev-Ayush 23/09/2024 */}
                {window.innerWidth > 1000 && <Grid sx={{ width: "100%", background: "#22273d" , paddingBottom : "100px"}}>
                <Grid sx={{ width: "100%", height : "55%", display: "flex",  }}>
                    <Box className="coin-tree" sx={{ '& img': { width: "234px", margin: "8% 0 0 30%" }, width: "40%", height: "100%" }}>
                        <img src={wvOne} alt="goldcoin" />
                    </Box>

                    <Grid sx={{ width: "55%", height: "100%", margin: "1% 0 0 3%" }}>
                        <Box sx={{ '& img': { width: "28%", margin: "8% 0 0 0%" }, }}>
                            <img src={wvTwo} alt="wvlogo" />
                        </Box>
                        <h1 className={styless.investgrowth}> Invest in Growth with BullForce </h1>
                        <h1 className={styless.wealthvaults}> Wealth Vaults! </h1>
                        <Typography className={styless.getsimple}> Get simple, smart investment portfolios curated by experts </Typography>
                    </Grid>
                </Grid>

                <Grid sx={{ width: "100%", height: "45%", display: "flex" }}>
                    
                    <Grid sx={{ width: "50%", height: "100%"}}>

                        <Box  className={styless.investnowbox}>
                            <Grid display="flex" >
                                <Box sx={{ "& img": { width: "56%", margin: "14% 0 0 18%" }, width: "30%"  }}>
                                    <img src={wvThree}  alt="wvlogo" className={styless.wealthVaultCardImage} />
                                </Box>

                                <Grid sx={{ margin: "4% 0 0 0", width: "40%" }}>
                                    <h1  className={styless.energetic}> Energetic </h1>
                                    <Typography  className={styless.highrisk}> (High risk) </Typography>
                                </Grid>

                                <Grid container sx={{ width: "40%", justifyContent: "center", alignItems: "center"  }} >
                                    <Button onClick={this.wealthVaultButton}  className={styless.investnowbutton}> Invest Now </Button>
                                </Grid>
                            </Grid>
                            <Divider  className={styless.divdr} sx={{ background: "#224673", margin: "0% 0 0 auto", width: "72%" }} />

                            <Grid className={styless.wealthVaultCard}>
                                <Grid  >
                                    {/* added ruppe symbol 27/09/24 -dev srini */}
                                    <Typography  className={styless.fourtykText}> ₹ 30,000</Typography>
                                    <Typography  className={styless.minInvestment}>Min. Investment</Typography>
                                </Grid>
                                <Grid >
                                    <Typography mt="33px"  className={styless.minInvestment1}>Multiples of ₹ 30,000</Typography>
                                </Grid>
                            </Grid>
                            
                        </Box>
                    </Grid>


                    <Grid sx={{ width: "50%", height: "100%" }}>
                        <Box  className={styless.traditionalbox}>
                            <Grid display="flex" >
                                <Box sx={{ "& img": { width: "56%",  }, width: "30%"}}>
                                    <img src={wvFive} alt="wvlogo" className={styless.wealthVaultCardImage} />
                                </Box>

                                <Grid sx={{ margin: "4% 0 0 0", width: "40%" }}>
                                    <h1 className={styless.energetic} style={{ whiteSpace: "nowrap" }}> Traditional </h1>
                                    <Typography  className={styless.highrisk}> (Low risk) </Typography>
                                </Grid>

                                <Grid container sx={{ width: "40%", justifyContent: "center", alignItems: "center"  }} >
                                    <Button onClick={this.wealthVaultButton}  className={styless.investnowbutton}> Invest Now </Button>
                                </Grid>
                            </Grid>

                            <Divider className={styless.divdr} sx={{ background: "#224673", margin: "0% 0 0 auto", width: "72%" }} />

                            <Grid className={styless.wealthVaultCard}>
                                <Grid  >
                                    <Typography  className={styless.fourtykText}> ₹ 30,000</Typography>
                                    <Typography  className={styless.minInvestment}>Min. Investment</Typography>
                                </Grid>
                                <Grid >
                                    <Typography mt="33px"  className={styless.minInvestment1}>Multiples of  ₹ 30,000</Typography>
                                </Grid>
                            </Grid>

                            
                        </Box>
                    </Grid>
                </Grid>
            </Grid>}

            {window.innerWidth <= 1000 && <Grid sx={{ width: "100%", background: "#22273d" }}>
                <Box sx={{ '& img': { width: window.innerWidth >= 600 && window.innerWidth < 1000 ? "210px" : "188px", margin: "8% 0 0 4%" }, }}>
                    <img src={wvTwo} alt="wvlogo" />
                </Box>

                <Grid display="flex" sx={{ width: "100%", margin: "4% 0 0 0"}} >
                    <Grid sx={{ width: "55%", height: "100%", margin: "0 0 0 4%" }}>
                        <h1  className = {styless.invstgrowmob}> Invest in </h1>
                        <h1  className = {styless.invstgrowmob} > Growth </h1>
                        <h1  className = {styless.invstgrowmob}> With </h1>
                        <h1  className = {styless.invstgrowmob}> BullForce </h1>
                        <h1  className = {styless.wltvalmob}> Wealth Vaults! </h1>
                    </Grid>

                    <Box sx={{ '& img': { width: "72%", margin: "6% 0 0 20%" }, width: "40%" }}>
                        <img src={wvOne} alt="goldcoin" />
                    </Box>
                </Grid>

                <Grid sx={{ margin: "0 0 0 4%", width: "90%", }}>
                    <Typography  className = {styless.getsimpmob}> Get simple, smart investment portfolios curated by experts </Typography>
                </Grid>

                <Grid sx={{ width: "100%"}}>

                    <Box  className = {styless.invtbxmob}>

                        <Grid display="flex" >
                            <Box sx={{ "& img": { width: window.innerWidth >= 600 && window.innerWidth < 1000? "46%" : "56%", margin: window.innerWidth < 400 ?  "20% 0 0 18%" : "14% 0 0 18%" }, width: "28%" }}>
                                <img  src={wvThree} alt="wvlogo" />
                            </Box>

                            <Grid sx={{ margin: "6% 0 0 0", width: "34%" }}>
                                <h1  className = {styless.enermob}> Energetic </h1>
                                <Typography  className = {styless.hirskmob} > (High risk) </Typography>
                            </Grid>

                            <Grid container sx={{ width: "38%", justifyContent: "center", alignItems: "center" }}>
                                <Button onClick={this.wealthVaultButton}  className = {styless.invtnwbtn}> Invest Now </Button>
                            </Grid>
                        </ Grid>

                        <Divider sx={{ background: "#224673", margin: "3% 0 0 0" }} />

                        <Grid className={styless.wealthVaultCard}>
                                <Grid  >
                                    <Typography  className={styless.fourtykText}>₹ 30,000</Typography>
                                    <Typography  className={styless.minInvestment}>Min. Investment</Typography>
                                </Grid>
                                <Grid >
                                    <Typography mt="33px"  className={styless.minInvestment1}>Multiples of ₹30,000</Typography>
                                </Grid>
                            </Grid>

                    </Box>
                </Grid>

                <Grid sx={{ width: "100%" }}>
                    <Box className = {styless.invtbxmob}>
                        <Grid display="flex" >
                            <Box sx={{ "& img": { width: window.innerWidth >= 600 && window.innerWidth < 1000? "46%" : "56%", margin: window.innerWidth < 400 ?  "20% 0 0 18%" : "14% 0 0 18%" }, width: "28%" }}>
                                <img src={wvFive} alt="wvlogo" />
                            </Box>

                            <Grid sx={{ margin: "6% 0 0 0", width: "34%" }}>
                                <h1 className = {styless.enermob} style={{ whiteSpace: "nowrap" }}> Traditional </h1>
                                <Typography  className = {styless.hirskmob} > (Low risk) </Typography>
                            </Grid>

                            <Grid container sx={{ width: "38%", justifyContent: "center", alignItems: "center" }}>
                                <Button  className = {styless.invtnwbtn} onClick={this.wealthVaultButton}> Invest Now </Button>
                            </Grid>
                        </ Grid> 

                        <Divider sx={{ background: "#224673", margin: "3% 0 0 0" }} />

                        <Grid className={styless.wealthVaultCard}>
                                <Grid  >
                                    <Typography  className={styless.fourtykText}>₹ 30,000</Typography>
                                    <Typography  className={styless.minInvestment}>Min. Investment</Typography>
                                </Grid>
                                <Grid >
                                    <Typography mt="33px"  className={styless.minInvestment1}>Multiples of ₹30,000</Typography>
                                </Grid>
                            </Grid>

                    </Box>
                </Grid>

                <Grid sx={{ width: "100%", height: "8vh",background: "#22273d" }}>

                </Grid>
            </Grid>}
            {/* end // wealth vault grid dev-Ayush 23/09/2024 */}

                {/*Referral */}
                <Grid
                    container
                    className={classes.refcard}
                >

<Grid style={{width:'100%',display:'flex',background:'#f74542'}}>
<Grid className={classes.ref_card}>
<img src={referall_img} alt='nse' style={{width:'90%'}} ></img>
</Grid>
<Grid className={classes.ref_card1}>
    {this.state.ref_page===0 && 
    <div>
<h1   className={classes.refcard_txt}>Referral program</h1>
  <Typography   className={classes.refcard_txt1}>Bullforce offers Referral Program for all their valuable patrons. This program enables the existing users to recommend Bullforce to their friends and family and make them a part of Bullforce community

  </Typography>
  <Typography   className={classes.refcard_txt1} style={{marginTop:'2%'}}>This involves 2 very simple steps.


  </Typography>
  <Typography   className={classes.refcard_txt1} style={{marginTop:'2%'}}>
  Generate your referral code by keying your account number and share the link within your network.
  </Typography>
{/* <span className={classes.refcard_txt2}> Rs. 3</span>  per executed order for every order placed by their respective affiliates.</Typography> */}




<Grid style={{marginTop:'4%',marginBottom:'4%'}}>
    <Button className={classes.referral_but2}
      onClick={() => this.setState({ref_page:1})}
    >
    Refer
    </Button>
</Grid>
</div>
}
{this.state.ref_page===1 && 
    <div>
<Typography   className={classes.refcard_txt}>Referral program</Typography>

<Grid className={classes.ref_card5}>
<Grid className={classes.ref_card2}>
<FormControl className={classes.ref_card7} variant="standard">
                                    <TextField size="small" variant="standard"


                                        className={classes.autofill_fix}
                                        label='Enter client code'
                                        fullWidth

                                        value={this.state.clentid}
                                        sx={{
                                            '& .MuiInput-underline:before': { borderBottomColor: '#FFFFFF' },
                                            '& .MuiInput-underline:hover:before': { borderBottomColor: '#FFFFFF' },
                                            '& .MuiInput-underline:after': { borderBottomColor: '#FFFFFF' },

                                        }}
                                        InputLabelProps={{
                                            style: { color: this.state.errtype === 11 ? "#FFFFFF" : '#FFFFFF', fontFamily: 'cardiuma-regular !important', fontSize: '12px' },


                                        }}
                                        InputProps={{
                                            classes: { input: classes.input2 },
                                            maxLength: 16,
                                            style: {color: "#FFFFFF", fontFamily: 'cardiuma-regular !important', fontSize: '18px', height: '30px' },


                                        }}
                                        onChange={(e) => this.setState({ clentid: e.target.value, errtype: 0 })}
                                    ></TextField>
                                    {this.state.errtype === 50 &&
                                        <FormHelperText className={classes.errtxt} id="component-error-text" sx={{color:"white !important"}}>{this.state.errorMsg}</FormHelperText>
                                    }
                                </FormControl>
                                </Grid>
                                
                                </Grid>
<Grid className={classes.ref_button_card}>
    <Button className={classes.referral_but}
      onClick={() => {this.cancelResetReferral()
        this.setState({ref_page:0, errtype:0, errorMsg:"" })
      }}
    >
    Cancel
    </Button>
    <Button className={classes.referral_but}
      onClick={() => this.VerifyReferral()}
    >
    Validate
    </Button>
</Grid>
</div>
}
{this.state.ref_page===2 && 
    <div>
<Typography   className={classes.refcard_txt}>Enter OTP</Typography>

<Grid style={{width:'100%',display:'flex',alignContent:'flex-end'}}>
<Grid className={classes.ref_card2}>
<Typography   className={classes.refcard_txt3}>Please provide us the OTP, Sent your phone number ******{this.state.mobilenumber.substring(6,10)} linked with client code {" "}{this.state.clentid.toString().toUpperCase()} </Typography>
<Grid style={{ display: 'flex', justifyContent: 'center', marginTop: '10px', }}>

<OtpInput

    numInputs={6}
    value={this.state.otp}
  
  
    
    shouldAutoFocus={true}
  

    inputStyle={{
        backgroundColor: 'transparent',
        width: "40px",
        height: "40px",


        border: "none",
        marginRight: "5px",
        color: "#ffffff ",
        fontSize: "16px",
        fontFamily: "cardiuma-M",
        borderBottom: '1px solid #ffffff',

    }}
    containerStyle={{
        justifyContent: "center",
        borderBottom: '10',
       
    }}
    renderInput={(props) => <input {...props} />}

    onChange={this.handleOTPChange}
/>
</Grid>
                                </Grid>
                                
                                </Grid>
<Grid className={classes.ref_button_card}>
    <Button className={classes.referral_but}
      onClick={() => this.setState({ref_page:0})}
    >
    Cancel
    </Button>
    <Button className={classes.referral_but}
      onClick={() => this.VerifyReferralOTP()}
    >
    Submit
    </Button>
</Grid>
</div>
}
{this.state.ref_page===3 && 
    <div>
<Typography   className={classes.refcard_txt}>Find your link below</Typography>

<Grid className={classes.ref_card6} >
<Grid className={classes.ref_card3}>
    <Typography   className={classes.reflinktxt}>{this.state.reflink}</Typography>

    <Grid style={{width:'10%', marginRight:'10px',cursor:'pointer',marginLeft:'10px'}}
    
    onClick={() => {this.CopyLink()}}
    >
<Grid style={{width:'100%',display:'flex',justifyContent:'center'}}>
    <img src={referall_copy} alt='copy'></img>
</Grid>
<Grid style={{width:'100%',display:'flex',justifyContent:'center'}}>
   <Typography style={{color:'#f74542',fontSize:'10px', fontFamily: "cardiuma-Bold",}}>{this.state.copytxt}</Typography>
</Grid>
    
    </Grid>
   
</Grid>



</Grid>

</div>
}
</Grid>

</Grid>
                </Grid>
                <Grid
                    container
                    className={classes.muilr_card_new}
                >
                    <Grid className={classes.muilthead_card}>
                        <h1 className={classes.muilthead}>
                            Multi exchange trading platform
                        </h1>
                    </Grid>

                    <Grid className={classes.muilthead_card1}>
                        <Grid className={classes.muilimg1}>
                            <img src={nse} alt='nse'></img>
                            <img src={bse} alt='nse' className={classes.bseimg}></img>
                            {/*    <img src={mcx} alt='nse' style={{ marginTop: '18px' }}></img>*/}
                        </Grid>
                        <Grid className={classes.muilimg}>

                            <Typography className={classes.muiltsub}>
                                Multi exchange trading is the key feature of the application.
                                Investors and subscribers could configure their personalized
                                robots to trade in various exchanges based on the scripts of
                                their interest and portfolios.
                            </Typography>
                        </Grid>
                        <Grid className={classes.muilimg}></Grid>
                    </Grid>
                </Grid>


                <Grid container className={classes.back_img} >
                    <Grid className={classes.dipmaincard}>

                        <Grid className={classes.dipmaincard_sub}>
                            <AnimatedOnScroll animationIn="bounceInLeft" >
                                <h1 className={classes.diplomatshead}>
                                    Bullforce Diplomats
                                </h1>
                            </AnimatedOnScroll>

                            <Grid className={classes.diplomat_im1}>

                                <img
                                    src={bitcoinLogo1}
                                    alt="bitcoinLogo"
                                    loading="lazy"
                                    style={{

                                        width: '273px',
                                        height: '500px',

                                        zIndex: 1

                                    }}
                                ></img>


                            </Grid>

                            <Typography className={classes.diplomatshead_sub}>
                                Bullforce is creating an exciting opportunity for Finfluencers to get honored and recognized as “Bullforce Diplomats”. Bullforce Diplomats are the face of the brand and they would join the leadership team in continuously achieving the business goals.
                            </Typography>



                        </Grid>


                    </Grid>
                    <Grid style={{
                        width: '50%',


                    }}>

                        <Grid className={classes.diplomat_im}>
                            <Grid style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <AnimatedOnScroll animationIn="bounceInRight" >
                                    <img
                                        src={bitcoinLogo}
                                        alt="bitcoinLogo"
                                        loading="lazy"
                                        style={{

                                            width: '600px',
                                            height: '900px',

                                            zIndex: 1

                                        }}
                                    ></img>



                                </AnimatedOnScroll>
                            </Grid>
                            <Grid style={{ display: 'flex', justifyContent: 'flex-end',marginTop:'-10%',marginRight:'12%' }}>
                                <AnimatedOnScroll animationIn="bounceInRight" >
                                    <img
                                        src={diplomats_img}
                                        alt="bitcoinLogo"
                                        loading="lazy"
                                        style={{

                                            width: '395px',
                                            height: '294px',

                                            zIndex: 1

                                        }}
                                    ></img>



                                </AnimatedOnScroll>
                            </Grid>
                        </Grid>


                    </Grid>
                    <Grid className={classes.diplomat_p2_card}>


                        <h1 className={classes.benifits}>
                            Benefits of Being Bullforce Diplomats{" "}
                        </h1>



                    </Grid>
                    <Grid className={classes.diplomat_p2_card2}>
                        <Grid style={{ width: '32%' }}>
                            <Card className={classes.benifitCards} >
                                <Grid style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                                    <div>
                                        <img src={highRewrds}
                                            width='125'
                                            height='92'
                                            alt="logo"
                                            loading="lazy"
                                        ></img>
                                    </div>


                                </Grid>
                                <Grid style={{ display: 'flex', width: '100%', justifyContent: 'center', }}>
                                    <h3 className={classes.benifitCardsTitle}>
                                        Brand Ambassadors
                                    </h3>
                                </Grid>
                                <Grid style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                                    <Typography className={classes.benifitCardsCont}>
                                        Bullforce diplomats would
                                        have the privileges of being
                                        the Brand Ambassadors of
                                        the Bullforce brand and they
                                        will assist in creating blogs
                                        and social media posts
                                    </Typography>
                                </Grid>
                            </Card>
                        </Grid>
                        <Grid style={{ width: '32%' }}>
                            <Card className={classes.benifitCards} >
                                <Grid style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                                    <div>
                                        <img src={easyGrowth}
                                            width='125'
                                            alt="logo"
                                            height='92'
                                            loading="lazy"
                                        ></img>
                                    </div>


                                </Grid>
                                <Grid style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                                    <h3 className={classes.benifitCardsTitle}>
                                        Growing the followers
                                    </h3>
                                </Grid>
                                <Grid style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                                    <Typography className={classes.benifitCardsCont}>
                                        While the brand image and
                                        brand value of the Bullforce
                                        continues to grow, it directly
                                        and indirectly catalyzes the
                                        growth in the social media
                                        followership of diplomats
                                    </Typography>
                                </Grid>
                            </Card>
                        </Grid>
                        <Grid style={{ width: '32%' }}>
                            <Card className={classes.benifitCards}>
                                <Grid style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                                    <div>
                                        <img src={highProd}
                                            width='125'
                                            alt="logo"
                                            height='92'
                                            loading="lazy"
                                        ></img>
                                    </div>
                                </Grid>
                                <Grid style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                                    <h3 className={classes.benifitCardsTitle}>
                                        Monetizing the network
                                    </h3>
                                </Grid>  <Grid style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                                    <Typography className={classes.benifitCardsCont}>
                                        Being the brand
                                        ambassadors, diplomats are
                                        entitled for a share in the
                                        earning. Thus creates an
                                        opportunity to monetize the
                                        social media network
                                    </Typography>
                                </Grid>
                            </Card>
                        </Grid>
                    </Grid>
                    <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <div
                            className={classes.diplomat_but_card2}
                        >
                            <Button
                                fullWidth
                                className='diplomat_but'
                                onClick={() => this.props.history.push('/diplomats', this.state)}
                            >
                                Join Us
                            </Button>
                        </div>
                    </Grid>
                </Grid>




                < Grid container id="franchisee" className={classes.connect_card}>


                    <h1 className={classes.connect_head}>
                        Bullforce Customer Connect
                    </h1>

                    <Grid className={classes.conectimgmaincard}>
                        <Grid className={classes.conectimgcars}>
                            <img
                                src={connect_img}
                                loading="lazy"
                                alt="trainCenterLogo"
                                style={{ width: "90%", marginRight: "5%" }}
                            ></img>
                        </Grid>
                        {this.state.applay_page === 0 &&
                            <Grid className={classes.connect_sub_card}>
                                <Typography className={classes.connect_sub}>
                                    Bullforce is opening franchise at taluka levels with the objective of offering superior quality of client services. The franchise (Sub Broker) is objectively termed as “Bullforce Customer Connect Centre”. Applications are invited from enthusiastic members for launching BCCC
                                </Typography>
                                <Grid className={classes.connect_but_card}>
                                    <Button
                                        fullWidth
                                        style={{ marginTop: '20px' }}

                                        className={classes.joinCoustmer}
                                        onClick={() => this.setState({ applay_page: 1 })}
                                    >
                                        Apply
                                    </Button>
                                </Grid>
                            </Grid>
                        }
                        {this.state.applay_page === 1 &&
                            <Grid className={classes.connect_sub_card1}>

                                <Typography className={classes.applay_txt}>
                                    Please fill below form we will get back to you in few working hours
                                </Typography>

                                <FormControl style={{ width: '100%', marginTop: '5px' }} variant="standard">
                                    <TextField size="small" variant="standard"


                                        className={classes.autofill_fix}
                                        label='Name'
                                        fullWidth

                                        value={this.state.conn_name}
                                        sx={{
                                            '& .MuiInput-underline:before': { borderBottomColor: '#92A4C1' },
                                            '& .MuiInput-underline:hover:before': { borderBottomColor: '#BCD171' },
                                            '& .MuiInput-underline:after': { borderBottomColor: '#BCD171' },

                                        }}
                                        InputLabelProps={{
                                            style: { color: this.state.errtype === 11 ? "#F74542" : '#92A4C1', fontFamily: 'cardiuma-regular !important', fontSize: '12px' },


                                        }}
                                        InputProps={{
                                            classes: { input: classes.input },
                                            maxLength: 16,
                                            style: {color: "#0B1420", fontFamily: 'cardiuma-regular !important', fontSize: '18px', height: '30px' },


                                        }}
                                        onChange={(e) => this.setState({ conn_name: e.target.value, errtype: 0 })}
                                    ></TextField>
                                    {this.state.errtype === 11 &&
                                        <FormHelperText className={classes.errtxt} id="component-error-text">{this.state.errorMsg}</FormHelperText>
                                    }
                                </FormControl>


                                <FormControl style={{ width: '100%', marginTop: '15px' }} variant="standard">
                                    <TextField size="small" variant="standard"


                                        className={classes.autofill_fix}
                                        label='Email'
                                        fullWidth

                                        value={this.state.conn_email}
                                        sx={{
                                            '& .MuiInput-underline:before': { borderBottomColor: '#92A4C1' },
                                            '& .MuiInput-underline:hover:before': { borderBottomColor: '#BCD171' },
                                            '& .MuiInput-underline:after': { borderBottomColor: '#BCD171' },

                                        }}
                                        InputLabelProps={{
                                            style: { color: this.state.errtype === 12 ? "#F74542" : '#92A4C1', fontFamily: 'cardiuma-regular !important', fontSize: '12px' },


                                        }}
                                        InputProps={{
                                            classes: { input: classes.input },
                                            maxLength: 16,
                                            style: {color: "#0B1420", fontFamily: 'cardiuma-regular !important', fontSize: '18px', height: '30px' },


                                        }}
                                        onChange={(e) => this.setState({ conn_email: e.target.value, errtype: 0 })}
                                    ></TextField>
                                    {this.state.errtype === 12 &&
                                        <FormHelperText className={classes.errtxt} id="component-error-text">{this.state.errorMsg}</FormHelperText>
                                    }
                                </FormControl>
                                <FormControl style={{ width: '100%', marginTop: '15px' }} variant="standard">
                                    <TextField size="small" variant="standard"


                                        className={classes.autofill_fix}
                                        label='Phone'
                                        fullWidth

                                        value={this.state.conn_phone}
                                        sx={{
                                            '& .MuiInput-underline:before': { borderBottomColor: '#92A4C1' },
                                            '& .MuiInput-underline:hover:before': { borderBottomColor: '#BCD171' },
                                            '& .MuiInput-underline:after': { borderBottomColor: '#BCD171' },

                                        }}
                                        InputLabelProps={{
                                            style: { color: this.state.errtype === 13 ? "#F74542" : '#92A4C1', fontFamily: 'cardiuma-regular !important', fontSize: '12px' },


                                        }}
                                        InputProps={{
                                            classes: { input: classes.input },
                                            maxLength: 16,
                                            style: {color: "#0B1420", fontFamily: 'cardiuma-regular !important', fontSize: '18px', height: '30px' },


                                        }}
                                        onChange={(e) => this.setState({ conn_phone: e.target.value, errtype: 0 })}
                                    ></TextField>
                                    {this.state.errtype === 13 &&
                                        <FormHelperText className={classes.errtxt} id="component-error-text">{this.state.errorMsg}</FormHelperText>
                                    }
                                </FormControl>
                                <Grid className={classes.connect_card_but}>
                                    <Grid className={classes.connect_card_but1}>
                                        <Button
                                            fullWidth
                                            style={{ marginTop: '25px' }}

                                            className={classes.connect_but}
                                            onClick={() => { this.cancelResetConn()
                                                this.setState({ applay_page: 0, errtype:0, errorMsg:""})
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                    </Grid>

                                    <Grid className={classes.connect_card_but1}>
                                        <Button
                                            fullWidth
                                            style={{ marginTop: '25px' }}

                                            className={classes.connect_but1}
                                            onClick={() => this.ConnSubmit()}
                                        >
                                            Submit
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                    </Grid>

                </Grid >
                < Grid container className={classes.academymaincard} >
                    <Grid className={classes.academyimgcard}>
                        <img className={classes.academyimg} src={academy_head} loading="lazy" alt='academy_head' />
                    </Grid>
                </Grid>

                <Grid className={classes.academyimgcard1}>
                    <img className={classes.academyimg1} src={academy_logo} loading="lazy" alt='academy_logo' />
                </Grid>

                <Grid className={classes.academymainheadcard}>
                    <h1 className={classes.academymainhead}>Bullforce Academy and Learning Centre</h1>

                </Grid>
                <Grid className={classes.academymainsubcard}>
                    <Typography className={classes.academymainsub}>Bullforce is committed to build an enlightened investor community. In this context, three levels of educational programs have been launched. These programs are absolutely complimentary from Bullforce. These programs are trainer assisted online courses with bidirectional communication and conducted in batches.</Typography>
                </Grid>

                <Grid container className={classes.academysubimagecard}>
                    <Grid className={classes.academysubimagecard1} >
                        <Grid className={classes.learnImage} style={{ width: '100%' }}>
                            <img src={t1} alt="traning"></img>
                        </Grid>
                        <Grid className={classes.learncard}>
                            <h3 className={classes.academysubtit}>Beginners Program</h3>
                            <Grid className={classes.academyDispGrid}>
                                <Typography className={classes.academydisp}>‣ Swing Trading v/s Day Trading</Typography>
                                <Typography className={classes.academydisp}>‣ How part time trading works</Typography>
                                <Typography className={classes.academydisp}>‣ Finding the best stock to Swing Trade</Typography>
                                <Grid style={{ display: 'flex' }}>
                                    <Typography className={classes.academydisp}>‣ Stock screening criteria </Typography>
                                    <Typography className={classes.moretxt}
                                    onClick={() => this.setState({ showModal: true })}>more ... </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid className={classes.academysubimagecard1}>
                        <Grid className={classes.learnImage} style={{ width: '100%' }}>
                            <img src={t2} alt='t2' loading="lazy"></img>
                        </Grid>
                        <Grid className={classes.learncard}>
                            <h3 className={classes.academysubtit1} >Advanced Program</h3>
                            <Grid className={classes.academyDispGrid}>
                                <Typography className={classes.academydisp}>‣ Trading strategies based on technical indicators </Typography>
                                <Typography className={classes.academydisp}>‣ Trading strategies based on patterns </Typography>
                                <Typography className={classes.academydisp}>‣ Trading with special patterns</Typography>
                                <Grid style={{ display: 'flex' }}>
                                    <Typography className={classes.academydisp}>‣ Trading strategies based on Elliot Wave </Typography>
                                    <Typography className={classes.moretxt}
                                    onClick={() => this.setState({ showModal: true })}
                                    >more ... </Typography>
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>
                    <Grid className={classes.academysubimagecard1}>
                        <Grid className={classes.learnImage} style={{ width: '100%', }}>
                            <img src={t3} alt='t3' loading="lazy"></img>
                        </Grid>
                        <Grid className={classes.learncard}>
                            <h3 className={classes.academysubtit2} >Mentor Program</h3>
                            <Grid className={classes.academyDispGrid}>
                                <Typography className={classes.academydisp}>‣ Course Introduction</Typography>
                                <Typography className={classes.academydisp}>‣ Options and the Stock Volatility, the VIX and more </Typography>
                                <Typography className={classes.academydisp}>‣ The basics of how Options work (Tickers, Call and Put)</Typography>
                                <Grid style={{ display: 'flex' }}>
                                    <Typography className={classes.academydisp}>‣ Pricing a Long or Short Call or Put </Typography>
                                    <Typography className={classes.moretxt}
                                    onClick={() => this.setState({ showModal: true })}
                                    >more ... </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                    <Grid className={classes.academybutcard}>
                        <Button
                            style={{ backgroundColor: "#F74542" , width : window.innerWidth <=960 ?  "243px" : "100%" }} 
                            fullWidth
                            className={classes.joinDiplomtsBtn}
                            onClick={() => this.setState({ showModal: true })}
                        >
                            Join Us
                        </Button>
                    </Grid>
                </Grid>

                {/*Incubation */}
                <Grid
                    container
                    className={classes.incubationmaincard}
                >
                    <h1 className={classes.incubationhead}>
                        Bullforce Incubation Program
                    </h1>
                    <Grid className={classes.incubationsubimg1}>
                        <img
                            src={incubationPrgm}
                            alt="incubationPrgm"
                            loading="lazy"
                            style={{ borderRadius: "12px", width: '100%' }}

                        ></img>
                    </Grid>
                    <Grid style={{ width: '100%', display: 'flex' }}>
                        <Grid className={classes.incubationsubcard}>
                            <Typography
                                className={classes.incubationsub}

                            >
                                We at Bullforce have a combined business experience of over 100 years in building products and startups at the leadership level. With this background, Bullforce intends to participate and invest in startups and guide them for success. We invites entrepreneurs with business and product ideas to approach us, present your thoughts and business cases. Bullforce Incubation Program creates
                            </Typography>
                            {this.state.incub_page === 1 &&
                        <Grid className={classes.connect_sub_card1} style={{ padding: '0px 0px 42px 0px' }}>

                            <Typography className={classes.applay_txt}>
                                Please fill below form we will get back to you in few working hours
                            </Typography>

                            <FormControl style={{ width: '100%', marginTop: '5px' }} variant="standard">
                                <TextField size="small" variant="standard"


                                    className={classes.autofill_fix}
                                    label='Name'
                                    fullWidth

                                    value={this.state.incub_name}
                                    sx={{
                                        '& .MuiInput-underline:before': { borderBottomColor: '#92A4C1' },
                                        '& .MuiInput-underline:hover:before': { borderBottomColor: '#BCD171' },
                                        '& .MuiInput-underline:after': { borderBottomColor: '#BCD171' },

                                    }}
                                    InputLabelProps={{
                                        style: { color: this.state.errtype === 21 ? "#F74542" : '#92A4C1', fontFamily: 'cardiuma-regular !important', fontSize: '12px' },


                                    }}
                                    InputProps={{
                                        classes: { input: classes.input },
                                        maxLength: 16,
                                        style: {color: "#0B1420", fontFamily: 'cardiuma-regular !important', fontSize: '18px', height: '30px' },


                                    }}
                                    onChange={(e) => this.setState({ incub_name: e.target.value, errtype: 0 })}
                                ></TextField>
                                {this.state.errtype === 21 &&
                                    <FormHelperText className={classes.errtxt} id="component-error-text">{this.state.errorMsg}</FormHelperText>
                                }
                            </FormControl>


                            <FormControl style={{ width: '100%', marginTop: '15px' }} variant="standard">
                                <TextField size="small" variant="standard"


                                    className={classes.autofill_fix}
                                    label='Email'
                                    fullWidth

                                    value={this.state.incub_email}
                                    sx={{
                                        '& .MuiInput-underline:before': { borderBottomColor: '#92A4C1' },
                                        '& .MuiInput-underline:hover:before': { borderBottomColor: '#BCD171' },
                                        '& .MuiInput-underline:after': { borderBottomColor: '#BCD171' },

                                    }}
                                    InputLabelProps={{
                                        style: { color: this.state.errtype === 22 ? "#F74542" : '#92A4C1', fontFamily: 'cardiuma-regular !important', fontSize: '12px' },


                                    }}
                                    InputProps={{
                                        classes: { input: classes.input },
                                        maxLength: 16,
                                        style: { color:"#0B1420", fontFamily: 'cardiuma-regular !important', fontSize: '18px', height: '30px' },


                                    }}
                                    onChange={(e) => this.setState({ incub_email: e.target.value, errtype: 0 })}
                                ></TextField>
                                {this.state.errtype === 22 &&
                                    <FormHelperText className={classes.errtxt} id="component-error-text">{this.state.errorMsg}</FormHelperText>
                                }
                            </FormControl>
                            <FormControl style={{ width: '100%', marginTop: '15px' }} variant="standard">
                                <TextField size="small" variant="standard"


                                    className={classes.autofill_fix}
                                    label='Phone'
                                    fullWidth

                                    value={this.state.incub_phone}
                                    sx={{
                                        '& .MuiInput-underline:before': { borderBottomColor: '#92A4C1' },
                                        '& .MuiInput-underline:hover:before': { borderBottomColor: '#BCD171' },
                                        '& .MuiInput-underline:after': { borderBottomColor: '#BCD171' },

                                    }}
                                    InputLabelProps={{
                                        style: { color: this.state.errtype === 23 ? "#F74542" : '#92A4C1', fontFamily: 'cardiuma-regular !important', fontSize: '12px' },


                                    }}
                                    InputProps={{
                                        classes: { input: classes.input },
                                        maxLength: 16,
                                        style: { color: "#0B1420", fontFamily: 'cardiuma-regular !important', fontSize: '18px', height: '30px' },


                                    }}
                                    onChange={(e) => this.setState({ incub_phone: e.target.value, errtype: 0 })}
                                ></TextField>
                                {this.state.errtype === 23 &&
                                    <FormHelperText className={classes.errtxt} id="component-error-text">{this.state.errorMsg}</FormHelperText>
                                }
                            </FormControl>
                            <Grid className={classes.connect_card_but}>
                                <Grid className={classes.connect_card_but1}>
                                    <Button
                                        fullWidth
                                        style={{ marginTop: '25px' }}

                                        className={classes.connect_but}
                                        onClick={() => { this.cancelResetIncub()
                                            this.setState({ incub_page: 0, errtype: 0 , errorMsg:"" })
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                </Grid>

                                <Grid className={classes.connect_card_but1}>
                                    <Button
                                        fullWidth
                                        style={{ marginTop: '25px' }}

                                        className={classes.connect_but1}
                                        onClick={() => this.IncubSubmit()}
                                    >
                                        Submit
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                    {this.state.incub_page === 0 &&
                        <Grid item md={12} style={{ padding: window.innerWidth <= 960 ? "0px 0px" : "0px 16px", alignItems: 'center' , marginTop : window.innerWidth > 960 ? "50px" : "20px"}}>
                            <Grid style={{ display: 'flex', alignItems: 'center' }}>
                                <img src={bullforceTick} loading="lazy" className={classes.bullforceTick_img} alt="bullforceTick"></img>
                                <Typography

                                    className={classes.incubationopt}
                                >

                                    Access to financial capital
                                </Typography>
                            </Grid>

                            <Grid style={{ display: 'flex', alignItems: 'center', marginTop: '15px' }}>
                                <img src={bullforceTick} loading="lazy" className={classes.bullforceTick_img} alt="bullforceTick"></img>
                                <Typography

                                    className={classes.incubationopt}
                                >
                                    Access to
                                    the market
                                </Typography>
                            </Grid>
                            <Grid style={{ display: 'flex', alignItems: 'center', marginTop: '15px' }}>
                                <img src={bullforceTick} loading="lazy" className={classes.bullforceTick_img} alt="bullforceTick"></img>
                                <Typography

                                    className={classes.incubationopt}
                                >
                                    Access to
                                    experienced management-level executives
                                </Typography>
                            </Grid>
                            <Grid style={{ display: 'flex', alignItems: 'center', marginTop: '15px' }}>
                                <img src={bullforceTick} loading="lazy" className={classes.bullforceTick_img} alt="bullforceTick"></img>
                                <Typography

                                    className={classes.incubationopt}
                                >
                                    Access to
                                    physical location space and business hardware or software
                                </Typography>
                            </Grid>
                            <Grid style={{ display: 'flex', alignItems: 'center', marginTop: '15px' }}>
                                <img src={bullforceTick} loading="lazy" className={classes.bullforceTick_img} alt="bullforceTick"></img>
                                <Typography

                                    className={classes.incubationopt}
                                >
                                    Access to
                                    information and research resources
                                </Typography>
                            </Grid>
                            <Grid className={classes.incubationbutcard}>
                                <div style={{ display: "flex", marginTop: '50px' }}>
                                    <Button
                                        fullWidth
                                        style={{ backgroundColor: " #F74542" }}
                                        className={classes.joinDiplomtsBtn}
                                        onClick={() => this.setState({ incub_page: 1 })}

                                    >
                                        Join Us
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>
                    }
                        </Grid>
                        <Grid className={classes.incubationsubimg}>
                            <img
                                src={incubationPrgm}
                                alt="incubationPrgm"
                                loading="lazy"
                                style={{ borderRadius: "12px" }}
                            ></img>
                        </Grid>

                    </Grid>
                    
                    

                </Grid>
                <Grid

                    className={classes.apicont_card}
                >
                    <h1 className={classes.bullforceAPIHead}>
                        Bullforce API Connect
                    </h1>
                    <br />

                    <Typography className={classes.franchiseTitle}

                    >
                        Build, integrate and trade with our comprehensive suite of
                        RESTful APIs and mobile SDKs.
                    </Typography>
                    <Typography
                        style={{
                            width: "100%",
                            textAlign: "center",
                            paddingTop: "30px",
                            color: '#fff'
                        }}
                        className={classes.franchiseTitle}
                    >
                        Trade in a language of your choice
                    </Typography>
                    <Typography
                        style={{ width: "100%", textAlign: "center" }}
                        className={classes.franchiseTitle}
                    >
                        Python I Java I Node I C#
                    </Typography>
                    <Typography className={classes.franchiseTitle}>
                        Track, trade & analyze with swift execution across all major
                        exchanges in varied segments including Cash, FNO, Commodity,
                        Currency and Mutual Funds with our robust APIs
                    </Typography>
                </Grid>
                <Grid container className={classes.apiConectBG}></Grid>
                <Grid

                    className={classes.apicont_card1}
                >
                    <h1 className={classes.accessBFList}>
                        Get access to Bullforce API Integrator which includes
                    </h1>

                    <Grid style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                        <img src={bullforceTick1} loading="lazy" className={classes.bullforceTick_img} alt="bullforceTick"></img>
                        <Typography className={classes.accessBFList2}>
                            Robust APIs
                            built to handle trading at scale
                        </Typography>
                    </Grid>

                    <Grid style={{ width: '100%', display: 'flex', marginTop: '15px', alignItems: 'center' }}>
                        <img src={bullforceTick1} loading="lazy" className={classes.bullforceTick_img} alt="bullforceTick"></img>
                        <Typography className={classes.accessBFList2}>
                            Access live
                            market feeds through WebSocket stream
                        </Typography>
                    </Grid>
                    <Grid style={{ width: '100%', display: 'flex', marginTop: '15px', alignItems: 'center' }}>
                        <img src={bullforceTick1} className={classes.bullforceTick_img} alt="bullforceTick"></img>
                        <Typography className={classes.accessBFList2}>
                            Secure
                            connection
                        </Typography>
                    </Grid>
                    <Grid style={{ width: '100%', display: 'flex', marginTop: '15px', alignItems: 'center' }}>
                        <img src={bullforceTick1} loading="lazy" className={classes.bullforceTick_img} alt="bullforceTick"></img>
                        <Typography className={classes.accessBFList2}>
                            View trades,
                            place orders and access your account securely in real time
                        </Typography>
                    </Grid>

                    <Grid style={{ width: '100%', display: 'flex', marginTop: '15px', alignItems: 'center' }}>
                        <img src={bullforceTick1} loading="lazy" className={classes.bullforceTick_img} alt="bullforceTick"></img>
                        <Typography className={classes.accessBFList2}>
                            Analyze your
                            trading behavior on APIs using our intuitive dashboard
                        </Typography>
                    </Grid>
                    <Grid style={{ width: '100%', display: 'flex', marginTop: '15px', alignItems: 'center' }}>
                        <img src={bullforceTick1} loading="lazy" className={classes.bullforceTick_img} alt="bullforceTick"></img>
                        <Typography className={classes.accessBFList2}>
                            Access to
                            quality customer support
                        </Typography>
                    </Grid>
                </Grid>
                <Grid

                    className={classes.downloadcard}

                >
                    <Grid>
                        <h1 className={classes.downloadTradeTitle}>
                            Download trading apps
                        </h1>

                    </Grid>
                    <Grid  className={classes.downloadimg} style={{  }}>

                        <img src={largeArrowIcon} loading="lazy" alt="downloadArrow"></img>
                    </Grid>

                    <Hidden only={["xs", "sm"]}>
                    <Grid item md={12} style={{ marginTop: '2%' }}>
                        <Grid
                            container
                            style={{ display: "flex", justifyContent: "space-around" }}
                        >
                            <Grid item md={3}>
                                <Card className={classes.benifitCards_app} style={{ justifyContent: 'center' }}
                                
                                onClick={() => window.open('https://play.google.com/store/apps/details?id=com.bullforce.xts', '_blank')}
                                >
                                    <img src={andriodIcon} loading="lazy" alt="androidIcon" ></img>
                                    <div style={{ display: "flex", cursor: 'pointer' }}>
                                        <h3 className={classes.benifitCardsTitle_app}>
                                            Android App
                                        </h3>
                                        <img
                                            src={arrowIcon}
                                            alt="logo"
                                            loading="lazy"
                                            style={{ marginLeft: "20px" }}
                                        ></img>
                                    </div>
                                </Card>
                            </Grid>

                            <Grid item md={3}>
                                <Card className={classes.benifitCards_app} style={{}}
                                
                                onClick={() => window.open('https://apps.apple.com/mt/app/bullforce/id6473841422', '_blank')}
                                >
                                    <img src={iosIcon} loading="lazy" alt="iosIcon"></img>
                                    <div style={{ display: "flex", cursor: 'pointer' }}>
                                        <h3 className={classes.benifitCardsTitle_app}>
                                            IOS App
                                        </h3>
                                        <img
                                            src={arrowIcon}
                                            style={{ marginLeft: "20px" }}
                                            loading="lazy"
                                            alt="logo"
                                        ></img>
                                    </div>
                                </Card>
                            </Grid>

                            <Grid item md={3}>
                                <Card className={classes.benifitCards_app} style={{}}
                                onClick={() => window.open('https://trade.bullforce.co/', '_blank')}
                                >
                                    <img src={webIcon} loading="lazy" alt="webIcon" width='250' height='210' ></img>
                                    <div style={{ display: "flex", cursor: 'pointer' }}>
                                        <h3 className={classes.benifitCardsTitle_app}>
                                            Web App
                                        </h3>
                                        <img
                                            src={arrowIcon}
                                            alt="logo"
                                            loading="lazy"
                                            style={{ marginLeft: "20px" }}
                                        ></img>
                                    </div>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>

                    </Hidden>
                    <Hidden only={["lg", "md", "xl"]}>
                    <Grid style={{width:'100%',padding:'16px'}} >
                      <Grid container style={{display:'flex',alignItems:'center', background:'#0B1420',borderRadius:'24px',height:'125px',width:'100%'}} >
                        <Grid style={{width:'40%',display:'flex',justifyContent:'center',alignItems:'center',}}>
                      <img src={andriodIcon} loading="lazy" alt="androidIcon" height="73px" width="59px" ></img>
                      </Grid>
                      <Grid style={{width:'60%',display:'flex',marginLeft:'-5%'}}
                      
                      onClick={() => window.open('https://play.google.com/store/apps/details?id=com.bullforce.xts', '_blank')}
                      >
                                        <h3 className={classes.benifitCardsTitle_app}>
                                            Android App
                                        </h3>
                                        <img
                                            src={arrowIcon}
                                            alt="logo"
                                            loading="lazy"
                                            style={{ marginLeft: "5px" }}
                                        ></img>
                                         </Grid>
                                        

                      </Grid>



                      <Grid style={{ display:'flex',alignItems:'center',marginTop:'15px', background:'#0B1420',borderRadius:'24px',height:'125px',width:'100%'}} >
                      <Grid style={{width:'30%',display:'flex',justifyContent:'center'}}>
                      <img src={iosIcon} loading="lazy" alt="androidIcon" height="80px" width="75px" ></img>
                      </Grid>
                      <Grid style={{width:'70%',display:'flex',}}
                      onClick={() => window.open('https://apps.apple.com/mt/app/bullforce/id6473841422', '_blank')}
                      >            
                                        <h3 className={classes.benifitCardsTitle_app} style={{marginLeft:'5%'}}>
                                        IOS App
                                        </h3>
                                        <img
                                            src={arrowIcon}
                                            alt="logo"
                                            loading="lazy"
                                            style={{ marginLeft: "5px" }}
                                        ></img>
                      </Grid>
                      </Grid>
                      <Grid style={{display:'flex',alignItems:'center',  marginTop:'15px', background:'#0B1420',borderRadius:'24px',height:'125px',paddingLeft:'25px'}} 
                      
                      onClick={() => window.open('https://trade.bullforce.co/', '_blank')}
                      >
                      <img src={webIcon} loading="lazy" alt="androidIcon" height="50px" width="70px"></img>
                                    
                                        <h3 className={classes.benifitCardsTitle_app} style={{marginLeft:'5%'}}>
                                        Web App
                                        </h3>
                                        <img
                                            src={arrowIcon}
                                            alt="logo"
                                            loading="lazy"
                                            style={{ marginLeft: "5px" }}
                                        ></img>

                      </Grid>
                      </Grid>
                        </Hidden>
                    <Grid className={classes.downloadimg1} >
                    <img src={largeArrowIcon} loading="lazy" alt="downloadArrow"></img>
                    </Grid>
                    
                </Grid>
                <Grid style={{ width: '100%' }}>
                    <Footer />
                </Grid>
                <Snackbar
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                    open={this.state.showerr}
                    autoHideDuration={3000}
                    onClose={() => this.closeContactModal()}

                >

                    <SnackbarContent
                        style={this.state.mysnack}
                        message={

                            <span style={{
                                display: "flex",
                                alignItems: "center",
                                color: "#333333",
                                fontSize: "12px",
                                fontFamily: "cardiuma-M"
                            }}>

                                {this.state.errorMsg}

                            </span>
                        }
                        action={
                            <React.Fragment>

                                <IconButton
                                    size="small"
                                    aria-label="close"
                                    color="inherit"
                                    onClick={() => { this.setState({ showerr: false }) }}
                                >
                                    <Close fontSize="small" />
                                </IconButton>
                            </React.Fragment>
                        }
                    >

                    </SnackbarContent>

                </Snackbar>
                
                <Modal 



                    
                    open={this.state.showModal}
                    onBackdropClick={this.closeModal}
                >

<Box
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '95%',
                            height: '92%',

                            outline: 'none',
                            background: '#FFFFFF',
                          padding:'10px',
                            borderRadius: '18px',

                          
                        }}
                    >
                              <div style={{ display: 'flex', }}>
                        <div style={{ flex: '70%', padding: '15px', marginRight: '50px', marginTop: '10px' }}>
                            <Typography className={classes.selecttitle} style={{ marginLeft: '20px' }}>
                                Selected programs for you</Typography>


                            <div className={classes.selectcard1}>
                                <div style={{ display: 'flex', marginBottom: '2px', marginTop: '5px' }}>
                                    <FormControlLabel
                                        style={{ marginLeft: '5px' }}
                                        className={classes.checkboxcard}

                                        control={
                                            <Checkbox

                                                checked={this.state.defaultBasicCheckBox}
                                                onClick={() => this.handleBasicPlan('Basic')}

                                                style={{ color: this.state.defaultBasicCheckBox && '#F74542' }}


                                            />
                                        }

                                    />
                                    <Grid className={classes.selectbar}>
                                        <Typography className={classes.selectheading}>
                                            Basic
                                        </Typography>
                                    </Grid>
                                </div>
                                <Grid container style={{ display: 'flex' }} >

                                    <Grid item style={{ flex: '50%' }} className={classes.listone}>

                                        <List  >
                                            {this.state.basic.slice(0, 5).map((basic, index) => (
                                                //<ListItem key={index} style={{ padding: '0px' }} className={classes.listicon}>
                                                  //  <ListItemText disableTypography className={classes.listpoints} primary={basic} />
                                                //</ListItem>
                                                <ListItem key={index} style={{ padding: '0px' }} className={classes.listicon}>
                                                <Typography  className={classes.listpoints} >{basic}</Typography>
                                                </ListItem>
                                            ))}
                                        </List>


                                    </Grid>
                                    <Grid item style={{ flex: '50%' }} className={classes.listtwo}>
                                        <List >
                                            {this.state.basic.slice(5, 10).map((basic, index) => (
                                                 <ListItem key={index} style={{ padding: '0px' }} className={classes.listicon}>
                                                 <Typography  className={classes.listpoints} >{basic}</Typography>
                                                 </ListItem>
                                            ))}
                                        </List>

                                    </Grid>
                                </Grid>
                            </div>


                            <div className={classes.selectcard2}>
                                <div style={{ display: 'flex', marginBottom: '2px', marginTop: '5px' }}>
                                    <FormControlLabel
                                        style={{ marginLeft: '5px' }}
                                        className={classes.checkboxcard}
                                        control={
                                            <Checkbox
                                                checked={this.state.defaultProfessionalCheckBox}
                                                onClick={() => this.handleProfessionalPlan('Professional')}
                                                style={{ color: this.state.defaultProfessionalCheckBox && '#F74542' }}

                                            />
                                        }

                                    />
                                    <Grid className={classes.selectbar}>
                                        <Typography className={classes.selectheading}>
                                            Professional (this takes minimum 3 days of 2 hours each)
                                        </Typography>
                                    </Grid>
                                </div>
                                <Grid container style={{ display: 'flex' }} >

                                    <Grid item style={{ flex: '50%' }} className={classes.listthree}  >
                                        <List>

                                            {this.state.professional.slice(0, 6).map((professional, index) => (
                                              //  <ListItem key={index} style={{ padding: 0 }} className={classes.listicon}>
                                                //    <ListItemText disableTypography className={classes.listpoints} primary={professional} />
                                                //</ListItem>
                                                <ListItem key={index} style={{ padding: '0px' }} className={classes.listicon}>
                                                <Typography  className={classes.listpoints} >{professional}</Typography>
                                                </ListItem>

                                            ))}

                                        </List>
                                    </Grid>
                                    <Grid item style={{ flex: '50%' }} className={classes.listfour}  >

                                        <List>
                                            {this.state.professional.slice(6, 12).map((professional, index) => (
                                               // <ListItem key={index} style={{ padding: 0 }} className={classes.listicon}>
                                                 //   <ListItemText disableTypography className={classes.listpoints} primary={professional} />

                                                //</ListItem>
                                                <ListItem key={index} style={{ padding: '0px' }} className={classes.listicon}>
                                                <Typography  className={classes.listpoints} >{professional}</Typography>
                                                </ListItem>

                                            ))}
                                        </List>


                                    </Grid>
                                </Grid>
                            </div>

                            <div className={classes.selectcard3}>

                                <div style={{ display: 'flex', marginBottom: '2px', marginTop: '5px' }}  >
                                    <FormControlLabel
                                        style={{ marginLeft: '5px' }}
                                        className={classes.checkboxcard}
                                        control={
                                            <Checkbox
                                                checked={this.state.defaultCompleteCheckBox}
                                                onClick={() => this.handleCompletePlan('Complete')}
                                                style={{ color: this.state.defaultCompleteCheckBox && '#F74542' }}

                                            />
                                        }

                                    />    <Grid className={classes.selectbar}>
                                        <Typography className={classes.selectheading}>
                                            Complete (this takes minimum 3 days of 2 hours each)
                                        </Typography>
                                    </Grid>

                                </div>
                                <Grid container style={{ display: 'flex' }} >
                                    <Grid style={{ flex: '50%' }} className={classes.listfive}>

                                        <List>
                                            {this.state.complete.slice(0, 6).map((complete, index) => (
                                               // <ListItem style={{ padding: 0 }} className={classes.listicon}>
                                                 //   <ListItemText disableTypography className={classes.listpoints} primary={complete} />
                                                //</ListItem>
                                                  <ListItem key={index} style={{ padding: '0px' }} className={classes.listicon}>
                                                  <Typography  className={classes.listpoints} >{complete}</Typography>
                                                  </ListItem>
                                            ))}
                                        </List>
                                    </Grid>
                                    <Grid item style={{ flex: '50%' }} className={classes.listsix}>
                                        <List>
                                            {this.state.complete.slice(6, 12).map((complete, index) => (
                                               // <ListItem style={{ padding: 0 }} className={classes.listicon} >

                                                 //   <ListItemText disableTypography className={classes.listpoints} primary={complete} />
                                                //</ListItem>
                                                <ListItem key={index} style={{ padding: '0px' }} className={classes.listicon}>
                                                <Typography  className={classes.listpoints} >{complete}</Typography>
                                                </ListItem>
                                            ))}
                                        </List>
                                    </Grid>
                                </Grid>
                            </div>

                        </div>
                        <div style={{ flex: '30%', padding: '8px' }}>
                            {/* 25% content (form in class component) */}

                            <Typography className={classes.selecttitle} style={{ marginTop: '20px' }} >
                                Bullforce Academy & Learning Centre
                            </Typography>

                            <Grid  >

                                <Grid style={{ width: '90%', marginLeft: '5px', marginTop: '10px' }}>
                                    <FormControl style={{ width: '100%' }} variant="standard">
                                        <TextField size="small" variant="standard"

                                            fullWidth
                                            sx={{
                                                '& .MuiInput-underline:before': {
                                                    borderBottomColor: '#92A4C1', width: '350px',
                                                    height: '1px',
                                                    flexshrink: 0,
                                                    borderRadius: '8px'
                                                },
                                                '& .MuiInput-underline:after': {
                                                    borderBottomColor: '#92A4C1', width: '350px',
                                                    height: '1px',
                                                    flexshrink: 0,
                                                    borderRadius: '8px'

                                                },
                                            }}
                                            InputLabelProps={{
                                                style: {
                                                    color: this.state.errtype === 1 ? '#92A4C1' : '#999',
                                                    fontFamily: 'cardiuma-regular !important',
                                                    fontSize: '14px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '400',
                                                    lineHeight: '20px'
                                                },
                                            }}
                                            label='Name'
                                            value={this.state.name}

                                            InputProps={{
                                                classes: { input: classes.input },
                                                maxLength: 12,
                                                style: {
                                                    width: '250px', height: '22.561px',
                                                    flexShrink: 0,
                                                    color: '#0B1420',
                                                    fontFamily: 'cardiuma-regular !important',
                                                    fontSize: '16px',
                                                    lineHeight: 'normal',
                                                    fontStyle: 'normal',
                                                    fontWeight: '400',
                                                }
                                            }}
                                            onChange={(e) => this.setState({ name: e.target.value, errtype: 0 })}
                                        ></TextField>
                                        {this.state.errtype === 1 &&
                                            <FormHelperText className={classes.errtxt} id="component-error-text">{this.state.errorMsg}</FormHelperText>
                                        }
                                    </FormControl>
                                </Grid>
                            </Grid>



                            <Grid  >

                                <Grid style={{ width: '90%', marginLeft: '5px', marginTop: '10px' }}>
                                    <FormControl style={{ width: '100%' }} variant="standard">
                                        <TextField size="small" variant="standard"

                                            fullWidth
                                            sx={{
                                                '& .MuiInput-underline:before': {
                                                    borderBottomColor: '#92A4C1', width: '350px',
                                                    height: '1px',
                                                    flexshrink: 0,
                                                    borderRadius: '8px'
                                                },
                                                '& .MuiInput-underline:after': {
                                                    borderBottomColor: '##92A4C1', width: '350px',
                                                    height: '1px',
                                                    flexshrink: 0,
                                                    borderRadius: '8px'
                                                },
                                            }}
                                            InputLabelProps={{
                                                style: {
                                                    color: this.state.errtype === 2 ? '#F74542' : '#999',
                                                    fontFamily: 'cardiuma-regular !important',
                                                    fontSize: '14px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '400',
                                                    lineHeight: '20px'
                                                }
                                            }}
                                            label='Email'
                                            value={this.state.email}

                                            InputProps={{
                                                classes: { input: classes.input },
                                                maxLength: 12,
                                                style: {
                                                    width: '250px', height: '22.561px',
                                                    flexShrink: 0,
                                                    color: '#0B1420',
                                                    fontFamily: 'cardiuma-regular !important',
                                                    fontSize: '16px',
                                                    lineHeight: 'normal',
                                                    fontStyle: 'normal',
                                                    fontWeight: '400',
                                                }
                                            }}
                                            onChange={(e) => this.setState({ email: e.target.value, errtype: 0 })}
                                        ></TextField>
                                        {this.state.errtype === 2 &&
                                            <FormHelperText className={classes.errtxt} id="component-error-text">{this.state.errorMsg}</FormHelperText>
                                        }
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid >

                                <Grid style={{ width: '90%', marginLeft: '5px', marginTop: '6px' }}>
                                    <FormControl style={{ width: '100%' }} variant="standard">
                                        <TextField size="small" variant="standard"
                                            fullWidth
                                            label='Phone no'
                                            value={this.state.mobilenumber}
                                            sx={{
                                                '& .MuiInput-underline:before': {
                                                    borderBottomColor: '#92A4C1',
                                                    width: '350px',
                                                    height: '1px',
                                                    flexshrink: 0,
                                                    borderRadius: '8px',

                                                },
                                                '& .MuiInput-underline:after': {
                                                    borderBottomColor: '#92A4C1',
                                                    width: '350px',
                                                    height: '1px',
                                                    flexshrink: 0,
                                                    borderRadius: '8px',
                                                },
                                                '& .MuiInputAdornment-root': {

                                                    color: '#ffffff',

                                                },
                                            }}
                                            InputLabelProps={{
                                                style: {
                                                    color: this.state.errtype === 3 ? '#F74542' : '#999',
                                                    fontFamily: 'cardiuma-regular !important',
                                                    fontSize: '14px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '400',
                                                    lineHeight: '20px'
                                                }
                                            }}

                                            InputProps={{
                                                classes: { input: classes.input },
                                                maxLength: 10,
                                                startAdornment: <InputAdornment position="start">
                                                    <Typography style={{ color: "black", fontFamily: 'cardiuma-regular !important', fontSize: '16px' }}>
                                                        +91
                                                    </Typography>
                                                </InputAdornment>,

                                                style: {
                                                    width: '250px', height: '22.561px',
                                                    flexShrink: 0,
                                                    color: '#0B1420',
                                                    fontFamily: 'cardiuma-regular !important',
                                                    fontSize: '16px',
                                                    lineHeight: 'normal',
                                                    fontStyle: 'normal',
                                                    fontWeight: '400',
                                                }
                                            }}
                                            onChange={(e) => this.setState({ mobilenumber: e.target.value, errtype: 0 })}
                                        ></TextField>
                                        {this.state.errtype === 3 &&
                                            <FormHelperText className={classes.errtxt} id="component-error-text">{this.state.errorMsg}</FormHelperText>
                                        }
                                    </FormControl>
                                </Grid>
                            </Grid>



                            <Grid container style={{ width: '90%', marginLeft: '1px', marginTop: '6px' }}>

                                <FormControl style={{ width: '100%' }} variant="standard">
                                    <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                                        <TextField
                                            size="small"
                                            variant="standard"
                                            fullWidth
                                            label=" Select your training date"
                                            value={this.state.selectDate}

                                            sx={{
                                                '& .MuiInput-underline:before': {
                                                    marginLeft: '3px',
                                                    borderBottomColor: '#92A4C1',
                                                    width: '350px',
                                                    height: '1px',
                                                    flexshrink: 0,
                                                    borderRadius: '8px'
                                                },
                                                '& .MuiInput-underline:after': {
                                                    marginLeft: '3px',
                                                    borderBottomColor: '#92A4C1',
                                                    width: '350px',
                                                    height: '1px',
                                                    flexshrink: 0,
                                                    borderRadius: '8px'

                                                },
                                                '& .MuiInputAdornment-root': {
                                                    color: '#ffffff',
                                                },
                                            }}

                                            InputLabelProps={{
                                                shrink: true,
                                                style: {

                                                    color: this.state.errtype === 7 ? '#F74542' : '#999',
                                                    marginLeft: '6px',
                                                    fontFamily: 'cardiuma-regular !important',
                                                    fontSize: '14px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '400',
                                                    lineHeight: '20px',

                                                }

                                            }}

                                            InputProps={{

                                                classes: { input: classes.input },
                                                // maxLength: 10,

                                                style: {
                                                    width: '250px',
                                                    height: '22.561px',
                                                    flexShrink: 0,
                                                    color: '#0B1420 ! important',
                                                    fontFamily: 'cardiuma-regular !important',
                                                    fontSize: '16px',
                                                    marginLeft: '5px',
                                                    lineHeight: 'normal ',
                                                    fontStyle: 'normal',
                                                    fontWeight: '400',
                                                },

                                            }}

                                        ></TextField>

                                        <img
                                            src={require('../../ui-assets/Frame.png')} // Update with your image path
                                            alt="Calendar Icon"
                                            style={{ width: '24px', height: '24px', cursor: 'pointer', fill: '#F74542', flexShrink: '0' }}
                                            onClick={this.openDateModal}
                                        />

                                    </div>

                                    {this.state.errtype === 7 && (
                                        <FormHelperText className={classes.errtxt} id="component-error-text">
                                            {this.state.errorMsg}
                                        </FormHelperText>
                                    )}

                                </FormControl>

                                <Modal
                                    open={this.state.datemodal}
                                    onClose={this.closeDateModal}
                                    aria-labelledby="modal-modal-title"
                                    aria-describedby="modal-modal-description"
                                    style={{
                                        position: 'absolute',
                                        top: 'calc(100% + 10px)',
                                        left: '10px',
                                        backgroundColor: 'white',
                                        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)'

                                    }}

                                >

                                    <div style={{ width: '20%', marginLeft: '74.77%', background: '#ffffff', borderRadius: '8px' }}>

                                        <LocalizationProvider dateAdapter={AdapterDayjs}>

                                            <DemoItem style={{ background: '#ffffff' }} >
                                                <DateCalendar
                                                    //selected={this.state.selectedDate}
                                                    shouldDisableDate={this.isDisabledDate}
                                                    onChange={this.handleChange}
                                                    minDate={this.state.minDate}
                                                    selected={this.state.selectDate}
                                                    //  value={this.state.selectDate}



                                                    sx={{
                                                        marginBottom: '-10px',
                                                        "& .MuiPickersDay-root": {
                                                            "&.Mui-selected": {
                                                                backgroundColor: '#F74542 !important',
                                                            },

                                                        },
                                                    }}

                                                >
                                                </DateCalendar>

                                            </DemoItem>

                                        </LocalizationProvider>


                                    </div>



                                </Modal>

                            </Grid>

                            <Grid container style={{ marginTop: '20px', marginLeft: '5px' }}>
                                <Grid item xs={12}>
                                    <FormControl component="fieldset">

                                        <Typography className={classes.buttonheading} style={{ color: this.state.errtype === 4 ? '#F74542' : '#3E3E3E' }} >
                                            Language selection
                                        </Typography>
                                        <div style={{ display: 'flex', gap: '5px', marginTop: '5px' }}>

                                            <Button
                                                value="hindi"
                                                onClick={() => this.setState({ selectLanguage: 'Hindi', errtype: 0 })}
                                                className={classes.buttoncard}
                                                style={{ background: (this.state.selectLanguage === 'Hindi' || this.state.isHoveredHindi) && '#F3D45E' }}
                                                onMouseEnter={() => this.setState({ isHoveredHindi: true })}
                                                onMouseLeave={() => this.setState({ isHoveredHindi: false })}

                                            >
                                                <span className={classes.buttontext}>Hindi</span>
                                            </Button>

                                            <Button
                                                value="english"
                                                onClick={() => this.setState({ selectLanguage: 'English', errtype: 0 })}
                                                className={classes.buttoncard}
                                                style={{ background: (this.state.selectLanguage === 'English' || this.state.isHoveredEnglish) && '#F3D45E' }}
                                                onMouseEnter={() => this.setState({ isHoveredEnglish: true })}
                                                onMouseLeave={() => this.setState({ isHoveredEnglish: false })}
                                            >
                                                <span className={classes.buttontext}>English</span>
                                            </Button>
                                        </div>
                                        {this.state.errtype === 4 && (
                                        <FormHelperText className={classes.errtxt} id="component-error-text">
                                            {this.state.errorMsg}
                                        </FormHelperText>
                                    )}
                                    </FormControl>
                                </Grid>

                            </Grid>
                            <Grid container style={{ marginTop: '15px', marginLeft: '5px' }} >
                                <Grid item xs={12}>
                                    <FormControl component="fieldset">
                                        <Typography className={classes.buttonheading} style={{ color: this.state.errtype === 5 ? '#F74542' : '#999' }}>
                                            Select your slots
                                        </Typography>
                                        <div style={{ display: 'flex', gap: '5px', marginTop: '5px' }}>
                                            <Button
                                                value="10AM-12PM"
                                                onClick={() => this.setState({ selectSlot: '10AM-12PM', errtype: 0 })}
                                                className={classes.buttoncard}
                                                style={{ background: (this.state.selectSlot === '10AM-12PM' || this.state.isHoveredslot1) && '#F3D45E' }}
                                                onMouseEnter={() => this.setState({ isHoveredslot1: true })}
                                                onMouseLeave={() => this.setState({ isHoveredslot1: false })}
                                            >
                                                <span className={classes.buttontext} >10AM-12PM</span>
                                            </Button>
                                            <Button
                                                value="2PM-3PM"
                                                onClick={() => this.setState({ selectSlot: '2PM-3PM', errtype: 0 })}
                                                className={classes.buttoncard}
                                                style={{ background: (this.state.selectSlot === '2PM-3PM' || this.state.isHoveredslot2) && '#F3D45E' }}
                                                onMouseEnter={() => this.setState({ isHoveredslot2: true })}
                                                onMouseLeave={() => this.setState({ isHoveredslot2: false })}

                                            >
                                                <span className={classes.buttontext}>2PM-3PM</span>
                                            </Button>
                                        </div>
                                        {this.state.errtype === 5 && (
                                        <FormHelperText className={classes.errtxt} id="component-error-text">
                                            {this.state.errorMsg}
                                        </FormHelperText>
                                    )}
                                    </FormControl>
                                </Grid>
                            </Grid>


                            <Grid container style={{ marginTop: '25px', }}>
                                <Grid item style={{ display: 'flex' }}>
                                    <Button onClick={()=>{this.cancelReset()
                                        this.setState({errtype:0,errorMsg:""})
                                    }}
                                        className={classes.cancelbutton}>Back </Button>
                                    <Button className={classes.submitbutton}
                                        onClick={() => this.submitJoiningForm()}
                                    >Submit</Button>
                                </Grid>
                            </Grid>
                        </div>

                    </div >
              
</Box>
                </Modal >
            </div >

        )
    }
}
export default (withStyles(styles)((landingpage)));
