import React, { Component } from 'react'
import { Grid, Typography, Button, TextField, Snackbar, SnackbarContent, IconButton, FormControl, FormHelperText } from '@mui/material'
import teamwork from "../../ui-assets/teamwork.svg";
import teamwork_mobile from "../../ui-assets/teamwork_mobile.svg";
import { withStyles, styled } from "@mui/styles";
import { Close } from '@mui/icons-material';

import { httpRequest } from '../../ui-utils/api'
import './diplomats.css'
import Fotter from '../landingpage/fotter';
import validator from 'validator'

const styles = theme => ({

    teamworkcard: {
        display: 'flex', width: '100%', justifyContent: 'center', padding: '0px',

        "@media screen and (max-width:960px)": {


            display: 'none',

        }
    },

    teamworkcard1: {

        display: 'none',
        "@media screen and (max-width:960px)": {

            display: 'flex', width: '100%', justifyContent: 'center', padding: '0px',


        }
    },

    input: {
        "&:-webkit-autofill": {
            WebkitBoxShadow: "0 0 0 1000px #ffffff inset",
            WebkitTextFillColor: '#1B2636'

        }
    },

    submitBtn: {
        background: "#F74542 !important",
        padding: "16px 24px !important",
        borderRadius: "38px !important",
        fontStyle: "normal !important",
        fontWeight: "100 !important",
        fontSize: "18px !important",
        lineHeight: "18px !important",
        color: "#ffffff !important",
        textTransform: "initial !important",
        fontFamily: "cardiuma-M !important",
        "@media screen and (max-width:960px)": {
            background: "#F74542 !important",
            padding: "12px 24px !important",
            borderRadius: "38px !important",
            fontSize: "14px !important",
        }
    },

    errtxt: {
        color: '#F74542 !important', fontFamily: 'cardiuma-regular !important', fontSize: '12px !important',
    },
 
    signupcard: {
        width: '100%', background: '#D8E2EC', height: 'auto', padding: '50px 0px 50px 0px',
        "@media screen and (max-width:960px)": {
            background: '#ffffff !important',
            padding: '0px 0px 0px 0px'
        }
    },

    signup_tit: {
        color: '#0B1420', fontSize: '32px !important', fontFamily: 'cardiuma-Bold !important',
        marginBlockStart: 0, marginBlockEnd: 0,

        "@media screen and (max-width:960px)": {
            fontSize: '20px !important'
        }
    },

    signupinfo_card: {
        marginTop: '40px', width: '100%', display: 'flex', justifyContent: 'center',
        "@media screen and (max-width:960px)": {
            marginTop: '1px',
        },
    },
    signupinfo_card1: {
        width: '40% !important', background: '#fff', borderRadius: '8px', padding: '30px 55px',
        "@media screen and (max-width:960px)": {
            width: '95% !important', padding: '0px 16px'
        }
    },

    dip_main2:{
    display: 'flex', width: '100%', justifyContent: 'center',
    "@media screen and (max-width:960px)": {
      display:'NOne'
    }
    },

    dip_main3:{
        display: 'None', width: '100%', justifyContent: 'center',
        "@media screen and (max-width:960px)": {
          display:'flex',width: '100%', padding:'16px',justifyContent:'flex-start'
        }
        },
        dip_vfont:{

             color: '#00ACFE', fontSize: '22px !important', fontFamily: 'cardiuma-Bold !important'
   

        },
        dip_vfont_disp:{

            color: '#FFFFFF', fontSize: '16px !important', fontFamily: 'cardiuma-regular !important'
  

       }

});

const snackeror = {
    myerror: {
        backgroundColor: "#ffffff",
        borderLeft: "8px solid #b60000",
        flexWrap: "nowrap",
        color: "#005100",
        borderTop: "1px solid #b60000",
        borderRight: "1px solid #b60000",
        borderBottom: "1px solid #b60000",
    },
    mysucc: {

        backgroundColor: "#ffffff",
        borderLeft: "8px solid #8db600",
        flexWrap: "nowrap",
        color: "#005100",
        borderTop: "1px solid #8db600",
        borderRight: "1px solid #8db600",
        borderBottom: "1px solid #8db600",
    }
};

class signup extends Component {
    constructor(props) {
        super(props)


        this.state = {
            mysnack: snackeror.mysucc,
            showerr: false,
            errorMsg: '',
            fname: '',
            lname: '',
            profession: '',
            errtype: 0,
            city: '',
            email: '',
            country: '',
            phoneno: '',
            socialmed: ''
        }
    }

    closeContactModal() {
        this.setState({ showerr: false })
    }

    componentDidMount = async () => {
        window.scrollTo(0, 0);
        window.Changemytab(1)
    }

    createAccount = async () => {
        const { fname, lname, profession, city, email, country, phoneno, socialmed } = this.state
        let isok = 0

        if (!fname.length && isok === 0) {

            isok = 1
            this.setState({ errtype: 1, errorMsg: "Enter the First Name" })

        }
        if(!lname.length && isok === 0 ){

            isok = 1
            this.setState({errtype: 6, errorMsg: "Enter the Last Name"})
        }

        
        if (!email.length && isok === 0) {
            
            isok = 1
            this.setState({ errtype: 2, errorMsg: "Enter the E-Mail" })
            
        }
        
        if (validator.isEmail(email) === false && isok === 0) {
            
            isok = 1
            this.setState({ errtype: 2, errorMsg: "E-Mail Not Vailed" })
            
        }
        if (!phoneno.length && isok === 0) {

            isok = 1
            this.setState({ errtype: 3, errorMsg: "Enter the Phone Number" })

        }

        if(validator.isMobilePhone(phoneno, 'en-IN') === false && isok === 0){

            isok = 1
            this.setState({ errtype: 3, errorMsg:"Enter valid Phone Number"})

        }

        if(!city.length && isok === 0 ){

            isok = 1
            this.setState({errtype: 7, errorMsg: "Enter the City"})
        }

        if(!country.length && isok === 0 ){

            isok = 1
            this.setState({errtype: 8, errorMsg: "Enter the Country"})
        }

        if(!profession.length && isok === 0 ){

            isok = 1
            this.setState({errtype: 4, errorMsg: "Enter the Profession"})
        }

        if(!socialmed.length && isok === 0 ){

            isok = 1
            this.setState({errtype: 5, errorMsg: "Enter the social media handle"})
        }

        if (isok === 0) {

            let payload = {
                fname: fname,
                lname: lname,
                profession: profession,
                city: city,
                country: country,
                phoneno: phoneno,
                email: email,
                socialmed: socialmed
            }

            try {
                const openaccount = await httpRequest({
                    endPoint: `api/user_form/diplomateaccount`,
                    method: "post",
                    instance: "instanceOne",
                    requestBody: payload,
                });
                console.log(openaccount.status)
                if (openaccount.status === 200) {

                    this.setState({
                        showerr: true, errorMsg:openaccount.message , mysnack: snackeror.mysucc,

                        fname: '', lname: '', email: '',profession:'',socialmed:'',phoneno:'',city:'',country:''
                    })

                } else {
                    this.setState({ showerr: true, errorMsg: openaccount.message, mysnack: snackeror.myerror })
                }
            } catch (error) {
                this.setState({ showerr: true, errorMsg: "oops something went wrong", mysnack: snackeror.myerror, 
                    fname: '', lname: '', email: '',profession:'',socialmed:'',phoneno:'',city:'',country:''

                })
            }
        }

    }

    render(){
        const { classes } = this.props;
        return(
            <div style={{marginTop:'65px'}}>          
            <Grid id="signup" className={classes.signupcard}>
                <Grid className={classes.dip_main2}>
                    <h1 className={classes.signup_tit}>Sign up to be a Bullforce Diplomat</h1>
                </Grid>
                <Grid className={classes.dip_main3}>
                    <Typography className={classes.signup_tit}>Bullforce Diplomats</Typography>
                </Grid>
                
                <Grid className={classes.signupinfo_card}>
                    <Grid container className={classes.signupinfo_card1} >

            
                        <Grid item lg={6} md={12} sm={12} xs={12}style={{ marginTop: '10px' }}>

                            <FormControl style={{width: window.innerWidth<1200 ? '100%':'90%'}} variant="standard">
                                <TextField size="small" variant="standard"



                                    label='First Name*'
                                    fullWidth

                                    value={this.state.fname}
                                    sx={{
                                        '& .MuiInput-underline:before': { borderBottomColor: '#92A4C1' },
                                        '& .MuiInput-underline:after': { borderBottomColor: '#BCD171' },


                                    }}
                                    InputLabelProps={{
                                        style: { color: this.state.errtype === 1 ? "#F74542" : '#92A4C1', fontFamily: 'cardiuma-regular !important', fontSize: '12px' },


                                    }}
                                    InputProps={{
                                        classes: { input: classes.input },
                                        maxLength: 16,
                                        style: { color: "#0B1420", fontFamily: 'cardiuma-regular !important', fontSize: '18px', height: '30px', },

                                    }}
                                    onChange={(e) => this.setState({ fname: e.target.value, errtype: 0 })}
                                ></TextField>
                                {this.state.errtype === 1 &&
                                    <FormHelperText className={classes.errtxt} id="component-error-text">{this.state.errorMsg}</FormHelperText>
                                }
                            </FormControl>

                        </Grid>
                        <Grid item lg={6} md={12} sm={12} xs={12} style={{ marginTop: '10px' }}>

                            <FormControl style={{ width: '100%' }} variant="standard">
                                <TextField size="small" variant="standard"
                                    label='Last Name*'
                                    fullWidth
                                    value={this.state.lname}
                                    sx={{
                                        '& .MuiInput-underline:before': { borderBottomColor: '#92A4C1' },
                                        '& .MuiInput-underline:after': { borderBottomColor: '#BCD171' },
                                    }}
                                    InputLabelProps={{
                                        style: { color: this.state.errtype === 6 ? "#F74542" : '#92A4C1', fontFamily: 'cardiuma-regular !important', fontSize: '12px' },
                                    }}
                                    InputProps={{
                                        classes: { input: classes.input },
                                        maxLength: 16,
                                        style: { color: "#0B1420", fontFamily: 'cardiuma-regular !important', fontSize: '18px', height: '30px', },
                                    }}
                                    onChange={(e) => this.setState({ lname: e.target.value, errtype: 0 })}
                                ></TextField>
                                {this.state.errtype === 6 &&
                                    <FormHelperText className={classes.errtxt} id="component-error-text">{this.state.errorMsg}</FormHelperText>
                                }
                            </FormControl>
                        </Grid>

                        <Grid item lg={6} md={12} sm={12} xs={12} style={{ marginTop: '10px' }}>
                            <FormControl style={{width: window.innerWidth<1200 ? '100%':'90%'}} variant="standard">
                                <TextField size="small" variant="standard"
                                    label='Email*'
                                    fullWidth
                                    value={this.state.email}
                                    sx={{
                                        '& .MuiInput-underline:before': { borderBottomColor: '#92A4C1' },
                                        '& .MuiInput-underline:after': { borderBottomColor: '#BCD171' },
                                    }}
                                    InputLabelProps={{
                                        style: { color: this.state.errtype === 2 ? "#F74542" : '#92A4C1', fontFamily: 'cardiuma-regular', fontSize: '12px' },
                                    }}
                                    InputProps={{
                                        classes: { input: classes.input },
                                        maxLength: 16,
                                        style: { color: "#0B1420", fontFamily: 'cardiuma-regular', fontSize: '18px', height: '30px', },
                                    }}
                                    onChange={(e) => this.setState({ email: e.target.value, errtype: 0 })}
                                ></TextField>
                                {this.state.errtype === 2 &&
                                    <FormHelperText className={classes.errtxt} id="component-error-text">{this.state.errorMsg}</FormHelperText>
                                }
                            </FormControl>
                        </Grid>
                        <Grid item lg={6} md={12} sm={12} xs={12} style={{ marginTop: '10px' }} >
                            <FormControl style={{ width: '100%' }} variant="standard">
                                <TextField size="small" variant="standard"
                                    label='Contact Number'
                                    fullWidth
                                    value={this.state.phoneno}
                                    sx={{
                                        '& .MuiInput-underline:before': { borderBottomColor: '#92A4C1' },
                                        '& .MuiInput-underline:after': { borderBottomColor: '#BCD171' },
                                    }}
                                    InputLabelProps={{
                                        style: { color: this.state.errtype === 3 ? "#F74542" : '#92A4C1', fontFamily: 'cardiuma-regular', fontSize: '12px' },
                                    }}
                                    InputProps={{
                                        classes: { input: classes.input },
                                        maxLength: 16,
                                        style: { color: "#0B1420", fontFamily: 'cardiuma-regular', fontSize: '18px', height: '30px', },
                                    }}
                                    onChange={(e) => this.setState({ phoneno: e.target.value, errtype: 0 })}
                                ></TextField>
                                {this.state.errtype === 3 &&
                                    <FormHelperText className={classes.errtxt} id="component-error-text">{this.state.errorMsg}</FormHelperText>
                                }
                            </FormControl>
                        </Grid>
                        <Grid item lg={6} md={12} sm={12} xs={12} style={{ marginTop: '10px' }}>
                            <FormControl style={{width: window.innerWidth<1200 ? '100%':'90%'}} variant="standard">
                                <TextField size="small" variant="standard"
                                    label='City*'
                                    fullWidth
                                    value={this.state.city}
                                    sx={{
                                        '& .MuiInput-underline:before': { borderBottomColor: '#92A4C1' },
                                        '& .MuiInput-underline:after': { borderBottomColor: '#BCD171' },
                                    }}
                                    InputLabelProps={{
                                        style: { color: this.state.errtype === 7 ? "#F74542" : '#92A4C1', fontFamily: 'cardiuma-regular', fontSize: '12px' },
                                    }}
                                    InputProps={{
                                        classes: { input: classes.input },
                                        maxLength: 16,
                                        style: { color: "#0B1420", fontFamily: 'cardiuma-regular', fontSize: '18px', height: '30px', },
                                    }}
                                    onChange={(e) => this.setState({ city: e.target.value, errtype: 0 })}
                                ></TextField>
                                {this.state.errtype === 7 &&
                                    <FormHelperText className={classes.errtxt} id="component-error-text">{this.state.errorMsg}</FormHelperText>
                                }
                            </FormControl>
                        </Grid>
                        <Grid item lg={6} md={12} sm={12} xs={12} style={{ marginTop: '10px' }}>
                            <FormControl style={{ width: '100%' }} variant="standard">
                                <TextField size="small" variant="standard"
                                    label='Country*'
                                    fullWidth
                                    value={this.state.country}
                                    sx={{
                                        '& .MuiInput-underline:before': { borderBottomColor: '#92A4C1' },
                                        '& .MuiInput-underline:after': { borderBottomColor: '#BCD171' },
                                    }}
                                    InputLabelProps={{
                                        style: { color: this.state.errtype === 8 ? "#F74542" : '#92A4C1', fontFamily: 'cardiuma-regular', fontSize: '12px' },
                                    }}
                                    InputProps={{
                                        classes: { input: classes.input },
                                        maxLength: 16,
                                        style: { color: "#0B1420", fontFamily: 'cardiuma-regular', fontSize: '18px', height: '30px', },
                                    }}
                                    onChange={(e) => this.setState({ country: e.target.value, errtype: 0 })}
                                ></TextField>
                                {this.state.errtype === 8 &&
                                    <FormHelperText className={classes.errtxt} id="component-error-text">{this.state.errorMsg}</FormHelperText>
                                }
                            </FormControl>
                        </Grid>
                        <Grid item md={12} sm={12} xs={12} style={{ marginTop: '10px' }}>

                            <FormControl style={{ width: '100%' }} variant="standard">
                                <TextField size="small" variant="standard"
                                    label='Profession'
                                    fullWidth
                                    value={this.state.profession}
                                    sx={{
                                        '& .MuiInput-underline:before': { borderBottomColor: '#92A4C1' },
                                        '& .MuiInput-underline:after': { borderBottomColor: '#BCD171' },
                                    }}
                                    InputLabelProps={{
                                        style: { color: this.state.errtype === 4 ? "#F74542" : '#92A4C1', fontFamily: 'cardiuma-regular', fontSize: '12px' },
                                    }}
                                    InputProps={{
                                        classes: { input: classes.input },
                                        maxLength: 16,
                                        style: { color: "#0B1420", fontFamily: 'cardiuma-regular', fontSize: '18px', height: '30px', },
                                    }}
                                    onChange={(e) => this.setState({ profession: e.target.value, errtype: 0 })}
                                ></TextField>
                                {this.state.errtype === 4 &&
                                    <FormHelperText className={classes.errtxt} id="component-error-text">{this.state.errorMsg}</FormHelperText>
                                }
                            </FormControl>
                        </Grid>

                        <Grid item md={12} sm={12} xs={12} style={{ marginTop: '10px' }}>
                            <FormControl style={{ width: '100%' }} variant="standard">
                                <TextField size="small" variant="standard"
                                    label='Social Media Handle'
                                    fullWidth
                                    value={this.state.socialmed}
                                    sx={{
                                        '& .MuiInput-underline:before': { borderBottomColor: '#92A4C1' },
                                        '& .MuiInput-underline:after': { borderBottomColor: '#BCD171' },
                                    }}
                                    InputLabelProps={{
                                        style: { color: this.state.errtype === 5 ? "#F74542" : '#92A4C1', fontFamily: 'cardiuma-regular', fontSize: '12px' },
                                    }}
                                    InputProps={{
                                        classes: { input: classes.input },
                                        maxLength: 16,
                                        style: { color: "#0B1420", fontFamily: 'cardiuma-regular', fontSize: '18px', height: '30px', },
                                    }}
                                    onChange={(e) => this.setState({ socialmed: e.target.value, errtype: 0 })}
                                ></TextField>
                                {this.state.errtype === 5 &&
                                    <FormHelperText className={classes.errtxt} id="component-error-text">{this.state.errorMsg}</FormHelperText>
                                }
                            </FormControl>
                        </Grid>
                        <Grid item md={12} sm={12} xs={12} style={{ margin: '25px', }}>
                            <Button fullWidth className={classes.submitBtn}

                                onClick={() => this.createAccount()}
                            >Submit</Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid className={classes.teamworkcard}>
                    <img src={teamwork} alt='teamwork'
                        style={{ height: '100%', width: '100%' }}>
                    </img>
                </Grid>
            </Grid>

            <Grid className={classes.teamworkcard1}>
                <img src={teamwork_mobile} alt='teamwork'
                    style={{ height: '100%', width: '100%' }}>

                </img>
            </Grid>

            <Grid style={{width:'100%'}}>
                <Fotter/>
            </Grid>
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right"
                }}
                open={this.state.showerr}
                autoHideDuration={3000}
                onClose={() => this.closeContactModal()}
            >
                <SnackbarContent
                    style={this.state.mysnack}
                    message={
                        <span style={{
                            display: "flex",
                            alignItems: "center",
                            color: "#333333",
                            fontSize: "12px",
                            fontFamily: "cardiuma-M"
                        }}>
                            {this.state.errorMsg}
                        </span>
                    }
                    action={
                        <React.Fragment>

                            <IconButton
                                size="small"
                                aria-label="close"
                                color="inherit"
                                onClick={() => { this.setState({ showerr: false }) }}
                            >
                                <Close fontSize="small" />
                            </IconButton>
                        </React.Fragment>
                    }
                >
                </SnackbarContent>
            </Snackbar>
        </div>
        )
    }
}
export default (withStyles(styles)((signup)));